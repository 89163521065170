import { Box, Button, Grid } from "@pankod/refine-mui";
import { City, Sector, Zone } from "interfaces/common";
import React, { useEffect, useRef, useState } from "react";
import { CityChart } from "./CityChart";
import { ZoneChart } from "./ZoneChart";
import { SectorChart } from "./SectorChart";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import format from "date-fns/format";
import axios from "axios";
export const Analytics = () => {
  const [cities, setCities] = useState<City[]>([]);
  const [zones, setZones] = useState<Zone[]>([]);
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [selectedZone, setSelectedZone] = useState<number | undefined>(1);
  const [selectedSector, setSelectedSector] = useState<string | null>(null);

  // Date Range Picker
  const [dateClicked, setDateClicked] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(addDays(new Date(), -15).setHours(0, 0, 0)),
      // startDate: new Date(new Date().setHours(0, 0, 0)),
      endDate: new Date(addDays(new Date(), 1).setHours(0, 0, 0)),
      key: "selection",
    },
  ]);
  const datePickerRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        datePickerRef.current &&
        !(datePickerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setDateClicked(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);

  //
  // Cities

  const getData = () => {
    const start = state[0].startDate;
    const end = state[0].endDate;
    axios
      .get("/api/historiques?startDate=" + start + "&endDate=" + end, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setCities(res.data);
          setSelectedCity(res.data[0]?.ville);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleClick = (event: any, chartContext: any, config: any) => {
    const index = config.dataPointIndex;
    const cityName = cities[index]?.ville;
    setSelectedCity(cityName ? cityName : "");
  };

  // Zones
  useEffect(() => {
    const fetchData = async () => {
      const start = state[0].startDate;
      const end = state[0].endDate;
      try {
        // const response = await fetch(`/api/historiques/zones/${selectedCity}`);
        const response = await fetch(
          `/api/historiques/zones/${selectedCity}?startDate=` +
            start +
            "&endDate=" +
            end
        );
        const data = await response.json();
        setZones(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [selectedCity, state]);

  const handleClickZone = (event: any, chartContext: any, config: any) => {
    const index = config.dataPointIndex;
    const zoneId = zones[index]?.historique?.map(
      (history) => history?.bin?.secteur?.ville?.id
    );
    if (zoneId && zoneId.length > 0) {
      const lastZoneId = zoneId.pop();
      const filteredZoneId = zoneId.filter((id) => id !== undefined);
      const finalZoneId =
        filteredZoneId.length > 0 ? filteredZoneId.pop() : lastZoneId;
      setSelectedZone(finalZoneId);
    }
  };
  // Sectors
  useEffect(() => {
    const start = state[0].startDate;
    const end = state[0].endDate;
    const fetchData = async () => {
      try {
        const response = await fetch(
          `/api/historiques/secteurs/${selectedZone}?startDate=` +
            start +
            "&endDate=" +
            end
        );
        const data = await response.json();

        setSectors(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [selectedZone, state]);

  const handleSectorClick = (event: any, chartContext: any, config: any) => {
    const index = config.dataPointIndex;
    const sectorName = sectors[index]?.secteur;
    setSelectedSector(sectorName ? sectorName : null);
  };

  // TotalPoids City
  const totalPoids =
    cities?.map((city) => {
      const sum = city?.historique?.reduce(
        (acc, curr) => acc + (curr?.poid || 0),
        0
      );
      return { ville: city.ville, totalPoids: sum || 0 };
    }) || [];

  // TotalPoids Zone
  const totalPoidsZone =
    zones?.map((zone) => {
      const sum = zone?.historique?.reduce(
        (acc, curr) => acc + (curr?.poid || 0),
        0
      );
      return { Zone: zone?.zone, totalPoidsZone: sum || 0 };
    }) || [];
  // TotalPoids Sector
  const totalPoidsSector =
    sectors?.map((sector) => {
      const sum = sector?.historique?.reduce(
        (acc, curr) => acc + (curr?.poid || 0),
        0
      );
      return { sector: sector?.secteur, totalPoidsSector: sum || 0 };
    }) || [];

  return (
    <Grid container minHeight="100vh" p={0} m={0}>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        p={2}
        sx={{ position: "relative", mb: 3 }}
      >
        <Box
          component="main"
          ref={datePickerRef}
          onClick={() => {
            setDateClicked(true);
          }}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            p: 1,
            border: 2,
            borderColor: "#E9E9E9",
            borderRadius: 2,
            overflow: "hidden",
            bgcolor: "#fff",
            cursor: "pointer",
            zIndex: 6,
            position: "absolute",
          }}
        >
          <Box
            sx={{
              width: "100%",
              color: "primary.main",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              fontFamily: "Inter",
            }}
          >
            {`${format(state[0].startDate, "dd/MM/yyyy")} to ${format(
              state[0].endDate,
              "dd/MM/yyyy"
            )}`}
          </Box>
          {dateClicked && (
            <Box
              sx={{
                mt: 3,
                zIndex: 50,
              }}
            >
              <DateRangePicker
                onChange={(item: any) => {
                  if (item.selection.startDate === item.selection.endDate) {
                    let newEndDate = new Date(
                      new Date(item.selection.endDate).setHours(23, 59, 59)
                    );
                    setState([{ ...item.selection, endDate: newEndDate }]);
                  } else {
                    setState([item.selection]);
                  }
                }}
                // showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="horizontal"
              />
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#fff",
                    color: "#000",
                    px: 3,
                  }}
                  type="button"
                  onClick={() => {
                    setDateClicked(false);
                  }}
                >
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    px: 3,
                  }}
                  onClick={(e) => {
                    getData();
                    setDateClicked(false);
                  }}
                >
                  Rechercher
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12} md={12} lg={12} p={2}>
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              width: "100%",
              p: 1,
            }}
          >
            <CityChart
              cities={cities}
              totalPoids={totalPoids}
              handleClick={handleClick}
            />
            {selectedCity && (
              <ZoneChart
                zones={zones}
                totalPoidsZone={totalPoidsZone}
                handleClickZone={handleClickZone}
                selectedCity={selectedCity}
              />
            )}
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={12} lg={12} p={2}>
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              width: "100%",
              p: 1,
            }}
          >
            <SectorChart
              sectors={sectors}
              totalPoidsSector={totalPoidsSector}
              handleSectorClick={handleSectorClick}
              selectedZone={selectedZone}
              selectedSector={selectedSector}
            />
          </Box>
        </Grid> */}
      </Grid>
    </Grid>
  );
};
