import { Box } from '@mui/material';
import { City } from 'interfaces/common';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
interface CityChartProps {
  cities: City[];
  totalPoids: { ville: string; totalPoids: number }[];
  handleClick: (event: any, chartContext: any, config: any) => void;
}
export const CityChart: React.FC<CityChartProps> = ({
  cities,
  totalPoids,
  handleClick,
}) => {
  const cityOptions = {
    chart: {
      id: 'city-chart',
      toolbar: {
        show: true,
      },
      events: {
        dataPointSelection: handleClick,
      },
    },
    labels: cities?.map((city) => city?.ville),
    series: totalPoids?.map((item) => item.totalPoids),

    dataLabels: {
      enabled: true, // enable data labels
    },
  };

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        border: 2,
        borderColor: '#E9E9E9',
        borderRadius: 2,
        overflow: 'hidden',
        bgcolor: '#fff',
        p: 1,
      }}
    >
      <ReactApexChart
        options={cityOptions}
        series={cityOptions.series}
        type="donut"
        height={400}
      />
    </Box>
  );
};
