import { Box } from '@mui/material';
import { Zone } from 'interfaces/common';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
interface ZoneChartProps {
  zones: Zone[];
  totalPoidsZone: { Zone: string; totalPoidsZone: number }[];
  handleClickZone: (event: any, chartContext: any, config: any) => void;
  selectedCity: string;
}
export const ZoneChart: React.FC<ZoneChartProps> = ({
  zones,
  totalPoidsZone,
  handleClickZone,
  selectedCity,
}) => {
  const zoneOptions = {
    chart: {
      id: 'zone-chart',
      toolbar: {
        show: true,
      },
      events: {
        dataPointSelection: handleClickZone,
      },
    },
    labels: zones?.map((zone) => zone.zone),
    series: totalPoidsZone?.map((item) => item.totalPoidsZone),
    dataLabels: {
      enabled: true,
    },
  };
  //   if (!zones || zones.length === 0) {
  //     return null;
  //   }
  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        border: 2,
        borderColor: '#E9E9E9',
        borderRadius: 2,
        overflow: 'hidden',
        bgcolor: '#fff',
        p: 1,
      }}
    >
      <ReactApexChart
        options={zoneOptions}
        series={zoneOptions.series}
        type="donut"
        height={400}
      />
    </Box>
  );
};
