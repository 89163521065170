import {
  HttpError,
  IResourceComponentsProps,
  useGetIdentity,
  useTranslate,
} from '@pankod/refine-core';
import InputMask from 'react-input-mask';
import {
  Autocomplete,
  Box,
  Create,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  useAutocomplete,
} from '@pankod/refine-mui';
import { Controller, useForm } from '@pankod/refine-react-hook-form';
import { Header } from 'components/common/Header';
import { ICoordinator, IIntegartor, IZone } from 'interfaces/common';
import { States } from 'constants/index';
import React, { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const CoordinatorCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const {
    refineCore: { formLoading },
    saveButtonProps,
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm<ICoordinator, HttpError, ICoordinator>();
  const [showPassword, setshowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setshowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [selectedVille, setSelectedVille] = useState<IZone | null>(null);
  const { autocompleteProps: villeAutocompleteProps } = useAutocomplete<IZone>({
    resource: 'villes/villes',
  });
  const { autocompleteProps: zoneAutocompleteProps } = useAutocomplete<IZone>({
    resource: `villes/${selectedVille?.ville}`,
  });

  const { autocompleteProps: IntegratorAutocompleteProps } =
    useAutocomplete<IIntegartor>({
      resource: 'integrateurs/integrateur',
    });

  //
  const { data: user } = useGetIdentity();
  const userRole = user && user.role;
  const userId = user && user?.integrateur?.id;

  useEffect(() => {
    setValue('integrateurId', userId);
  }, [setValue, userId]);
  //
  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={
        <Header
          title={t('user.coordinator.titles.create')}
          description={t('user.coordinator.titles.description')}
        />
      }
      wrapperProps={{
        sx: {
          border: 2,
          borderColor: '#E9E9E9',
          borderRadius: 2,
        },
      }}
    >
      <>
        <Box
          component="div"
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            bgcolor: '#fff',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Stack
            sx={{ width: '100%', justifyContent: 'space-between', gap: 3 }}
          >
            {userRole === 'Integrateur' && (
              <input type="hidden" {...register('integrateurId')} />
            )}
            <Stack
              direction="row"
              sx={{
                width: '100%',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              {/* First Name */}

              <FormControl fullWidth>
                <FormLabel
                  required
                  sx={{
                    marginBottom: '8px',
                    fontFamily: 'Inter',
                    fontWeight: '700',
                    fontSize: '14px',
                    color: 'text.primary',
                  }}
                >
                  {t('user.fields.firstName')}
                </FormLabel>
                <TextField
                  {...register('prenom', {
                    required: t('errors.required.field', {
                      field: 'First Name',
                    }),
                  })}
                  size="medium"
                  margin="none"
                  variant="outlined"
                  error={!!errors.prenom?.message}
                  InputProps={{
                    inputProps: {
                      style: { textTransform: 'capitalize' },
                      maxLength: 50, // maximum length of characters allowed
                      onChange: (event) => {
                        const target = event.target as HTMLInputElement;
                        target.value =
                          target.value.charAt(0).toUpperCase() +
                          target.value.slice(1);
                      },
                    },
                  }}
                />
                {errors.prenom && (
                  <FormHelperText error>{errors.prenom.message}</FormHelperText>
                )}
              </FormControl>
              {/* Last Name */}

              <FormControl fullWidth>
                <FormLabel
                  required
                  sx={{
                    marginBottom: '8px',
                    fontFamily: 'Inter',
                    fontWeight: '700',
                    fontSize: '14px',
                    color: 'text.primary',
                  }}
                >
                  {t('user.fields.lastName')}
                </FormLabel>
                <TextField
                  {...register('nom', {
                    required: t('errors.required.field', {
                      field: 'Last Name',
                    }),
                  })}
                  size="medium"
                  margin="none"
                  variant="outlined"
                  error={!!errors.nom?.message}
                  InputProps={{
                    inputProps: {
                      style: { textTransform: 'capitalize' },
                      maxLength: 50, // maximum length of characters allowed
                      onChange: (event) => {
                        const target = event.target as HTMLInputElement;
                        target.value =
                          target.value.charAt(0).toUpperCase() +
                          target.value.slice(1);
                      },
                    },
                  }}
                />
                {errors.nom && (
                  <FormHelperText error>{errors.nom.message}</FormHelperText>
                )}
              </FormControl>
            </Stack>

            {/* Email */}
            <FormControl>
              <FormLabel
                required
                sx={{
                  marginBottom: '8px',
                  fontFamily: 'Inter',
                  fontWeight: '700',
                  fontSize: '14px',
                  color: 'text.primary',
                }}
              >
                {t('user.fields.email')}
              </FormLabel>
              <TextField
                {...register('email', {
                  required: t('errors.required.field', { field: 'Email' }),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('errors.required.invalidMail'),
                  },
                })}
                size="medium"
                margin="none"
                variant="outlined"
                error={!!errors.email?.message}
              />
              {errors.email && (
                <FormHelperText error>{errors.email.message}</FormHelperText>
              )}
            </FormControl>
            {/* Password */}
            <FormControl variant="outlined">
              <FormLabel
                required
                sx={{
                  marginBottom: '8px',
                  fontFamily: 'Inter',
                  fontWeight: '700',
                  fontSize: '14px',
                  color: 'text.primary',
                }}
              >
                {t('user.fields.password')}
              </FormLabel>

              <OutlinedInput
                id="outlined"
                type={showPassword ? 'text' : 'password'}
                {...register('password', {
                  required: t('errors.required.field', {
                    field: 'Password',
                  }),
                })}
                error={!!errors.password?.message}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                sx={{ backgroundColor: 'white' }}
                fullWidth
              />
              {errors.password && (
                <FormHelperText error>{errors.password.message}</FormHelperText>
              )}
            </FormControl>
            {/* Phone Number */}
            <FormControl>
              <FormLabel
                required
                sx={{
                  marginBottom: '8px',
                  fontFamily: 'Inter',
                  fontWeight: '700',
                  fontSize: '14px',
                  color: 'text.primary',
                }}
              >
                {t('user.fields.gsm')}
              </FormLabel>
              <InputMask
                mask="99 99 99 99 99"
                disabled={false}
                {...register('numeroTel', {
                  required: t('errors.required.field', { field: 'Phone' }),
                })}
              >
                {/* @ts-expect-error False alarm */}
                {(props: TextFieldProps) => (
                  <TextField
                    {...props}
                    size="medium"
                    margin="none"
                    variant="outlined"
                    error={!!errors.nom?.message}
                  />
                )}
              </InputMask>
              {errors.numeroTel && (
                <FormHelperText error>
                  {errors.numeroTel.message}
                </FormHelperText>
              )}
            </FormControl>
            {/* Integrator */}
            {userRole === 'Admin' && (
              <FormControl fullWidth>
                <FormLabel
                  required
                  sx={{
                    marginBottom: '8px',
                    fontFamily: 'Inter',
                    fontWeight: '700',
                    fontSize: '14px',
                    color: 'text.primary',
                  }}
                >
                  {t('user.fields.integrator')}
                </FormLabel>
                <Controller
                  control={control}
                  name="integrateurId"
                  rules={{
                    required: 'Integrator is required',
                  }}
                  render={({ field }) => (
                    <Autocomplete
                      size="medium"
                      {...IntegratorAutocompleteProps}
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value?.id);
                      }}
                      getOptionLabel={(item) => {
                        return item.nom && item.prenom
                          ? `${item.nom} ${item.prenom}`
                          : '';
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value === undefined || option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.integrateurId?.message}
                          required
                        />
                      )}
                    />
                  )}
                />
                {errors.integrateurId && (
                  <FormHelperText error>
                    {errors.integrateurId.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Stack>
          <Stack
            direction="row"
            sx={{ width: '100%', justifyContent: 'space-between', gap: 2 }}
          >
            {/* Ville */}
            <FormControl fullWidth>
              <FormLabel
                required
                sx={{
                  marginBottom: '8px',
                  fontFamily: 'Inter',
                  fontWeight: '700',
                  fontSize: '14px',
                  color: 'text.primary',
                }}
              >
                {t('sector.fields.city')}
              </FormLabel>
              <Controller
                control={control}
                name="ville"
                rules={{
                  required: 'City required',
                }}
                render={({ field }) => (
                  <Autocomplete
                    size="medium"
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    options={States.map((state) => state.state_name)}
                    // }
                    renderInput={(params) => (
                      <TextField
                        {...register('ville', {
                          required: t('errors.required.field', {
                            field: 'City',
                          }),
                        })}
                        {...params}
                        variant="outlined"
                        error={!!errors.ville?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              {errors.ville && (
                <FormHelperText error>{errors.ville.message}</FormHelperText>
              )}
            </FormControl>
            {/* <FormControl fullWidth>
              <FormLabel
                required
                sx={{
                  marginBottom: '8px',
                  fontWeight: '700',
                  fontSize: '14px',
                  color: 'text.primary',
                }}
              >
                {t('zone.fields.city')}
              </FormLabel>
              <Controller
                control={control}
                name="villeId"
                rules={{
                  required: 'City is required',
                }}
                render={({ field }) => (
                  <Autocomplete
                    size="medium"
                    {...villeAutocompleteProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value?.id);
                      setSelectedVille(value);
                    }}
                    getOptionLabel={(item) => {
                      return item.ville ? item.ville : '';
                    }}
                    isOptionEqualToValue={(option, value) =>
                      value === undefined || option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={!!errors.villeId?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              {errors.villeId && (
                <FormHelperText error>{errors.villeId.message}</FormHelperText>
              )}
            </FormControl> */}
            {/* Zone */}
            {/* <FormControl fullWidth>
              <FormLabel
                required
                sx={{
                  marginBottom: '8px',
                  fontWeight: '700',
                  fontSize: '14px',
                  color: 'text.primary',
                }}
              >
                {t('zone.fields.zone')}
              </FormLabel>
              <Controller
                control={control}
                name="villeId"
                rules={{
                  required: 'City is required',
                }}
                render={({ field }) => (
                  <Autocomplete
                    size="medium"
                    {...zoneAutocompleteProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value?.id);
                    }}
                    getOptionLabel={(item) => {
                      return item.zone ? item.zone : '';
                    }}
                    isOptionEqualToValue={(option, value) =>
                      value === undefined || option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={!!errors.villeId?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              {errors.villeId && (
                <FormHelperText error>{errors.villeId.message}</FormHelperText>
              )}
            </FormControl> */}
          </Stack>
        </Box>
      </>
    </Create>
  );
};
