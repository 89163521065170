import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useDelete,
  useNavigation,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  List,
} from "@pankod/refine-mui";

import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

import { IType } from "../../../interfaces/common";

export const TypesList: React.FC<IResourceComponentsProps> = () => {
  const { edit } = useNavigation();
  const t = useTranslate();
  const { mutate: mutateDelete } = useDelete();

  const { dataGridProps } = useDataGrid<IType>({
    initialPageSize: 10,
    initialSorter: [],
  });

  const columns = React.useMemo<GridColumns<IType>>(
    () => [
      {
        field: "type",
        headerName: t("pdc.fields.pdc"),
        flex: 1,
        minWidth: 200,
      },

      {
        field: "actions",
        headerName: t("table.actions"),
        type: "actions",
        getActions: function render({ row }) {
          return [
            <GridActionsCellItem
              key={1}
              label={t("buttons.edit")}
              icon={<Edit color="success" />}
              onClick={() => edit("types", row.id)}
            />,

            <GridActionsCellItem
              key={2}
              label={t("buttons.delete")}
              icon={<DeleteIcon color="error" />}
              onClick={() => {
                mutateDelete({
                  resource: "types",
                  id: row.id,
                  mutationMode: "undoable",
                  undoableTimeout: 10000,
                });
              }}
            />,
          ];
        },
      },
    ],
    [t]
  );
  return (
    <List
      wrapperProps={{
        sx: {
          paddingX: { xs: 2, md: 0 },
          border: 2,
          borderColor: "#E9E9E9",
          borderRadius: 2,
        },
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        density="comfortable"
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}
      />
    </List>
  );
};
