export const States = [
  { state_id: '1', state_name: 'Adrar' },
  { state_id: '2', state_name: 'Chlef' },
  { state_id: '3', state_name: 'Laghouat' },
  { state_id: '4', state_name: 'Oum El Bouaghi' },
  { state_id: '5', state_name: 'Batna' },
  { state_id: '6', state_name: 'Bejaia' },
  { state_id: '7', state_name: 'Biskra' },
  { state_id: '8', state_name: 'Bechar' },
  { state_id: '9', state_name: 'Blida' },
  { state_id: '10', state_name: 'Bouira' },
  { state_id: '11', state_name: 'Tamanghasset' },
  { state_id: '12', state_name: 'Tebessa' },
  { state_id: '13', state_name: 'Tlemcen' },
  { state_id: '14', state_name: 'Tiaret' },
  { state_id: '15', state_name: 'Tizi Ouzou' },
  { state_id: '16', state_name: 'Alger Centre' },
  { state_id: '17', state_name: 'Djelfa' },
  { state_id: '18', state_name: 'Jijel' },
  { state_id: '19', state_name: 'Setif' },
  { state_id: '20', state_name: 'Saida' },
  { state_id: '21', state_name: 'Skikda' },
  { state_id: '22', state_name: 'Sidi Bel Abbes' },
  { state_id: '23', state_name: 'Annaba' },
  { state_id: '24', state_name: 'Guelma' },
  { state_id: '25', state_name: 'Constantine' },
  { state_id: '26', state_name: 'Medea' },
  { state_id: '27', state_name: 'Mostaganem' },
  { state_id: '28', state_name: 'Msila' },
  { state_id: '29', state_name: 'Mascara' },
  { state_id: '30', state_name: 'Ouargla' },
  { state_id: '31', state_name: 'Oran' },
  { state_id: '32', state_name: 'El Bayadh' },
  { state_id: '33', state_name: 'Illizi' },
  { state_id: '34', state_name: 'Bordj Bou Arreridj' },
  { state_id: '35', state_name: 'Boumerdes' },
  { state_id: '36', state_name: 'El Tarf' },
  { state_id: '37', state_name: 'Tindouf' },
  { state_id: '38', state_name: 'Tissemsilt' },
  { state_id: '39', state_name: 'El Oued' },
  { state_id: '40', state_name: 'Khenchela' },
  { state_id: '41', state_name: 'Souk Ahras' },
  { state_id: '42', state_name: 'Tipaza' },
  { state_id: '43', state_name: 'Mila' },
  { state_id: '44', state_name: 'Ain Defla' },
  { state_id: '45', state_name: 'Naama' },
  { state_id: '46', state_name: 'Ain Temouchent' },
  { state_id: '47', state_name: 'Ghardaia' },
  { state_id: '48', state_name: 'Relizane' },
  { state_id: '49', state_name: 'Timimoun' },
  { state_id: '50', state_name: 'Bordj Badji Mokhtar' },
  { state_id: '51', state_name: 'Ouled Djellal' },
  { state_id: '52', state_name: 'Béni Abbès' },
  { state_id: '53', state_name: 'In Saleh' },
  { state_id: '54', state_name: 'In Guezzam' },
  { state_id: '55', state_name: 'Touggourt' },
  { state_id: '56', state_name: 'Djanet' },
  { state_id: '57', state_name: "El M'Ghair" },
  { state_id: '58', state_name: 'El Meniaa' },
];
