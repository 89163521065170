import React, { useState, useEffect } from 'react';
import {
  IResourceComponentsProps,
  useTranslate,
  HttpError,
  useApiUrl,
  useCustom,
} from '@pankod/refine-core';
import {
  Button,
  Edit,
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  SaveButton,
  Stack,
  Step,
  Stepper,
  StepButton,
  TextField,
  useAutocomplete,
  Autocomplete,
  Typography,
} from '@pankod/refine-mui';
import { useStepsForm, Controller } from '@pankod/refine-react-hook-form';
import { Header } from 'components/common/Header';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { IBin, ISector, IType, IZone } from 'interfaces/common';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useParams } from '@pankod/refine-react-router-v6';
export const BinEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const stepTitles = [
    t('bins.steps.data'),
    t('bins.steps.location'),
    t('bins.steps.validate'),
  ];

  const {
    refineCore: { onFinish, formLoading, queryResult },
    control,

    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    steps: { currentStep, gotoStep },
  } = useStepsForm<IBin, HttpError, IBin>({
    stepsProps: {
      isBackValidate: false,
    },
    warnWhenUnsavedChanges: true,
  });

  // State
  type BinStatusProps = {
    status?: 'Actif' | 'Pending';
  };

  const [etat, setEtat] = useState<BinStatusProps['status']>('Pending');
  const [date_deploiement, setDate_deploiement] = useState('');

  useEffect(() => {
    const latitude = watch('latitude');
    const longitude = watch('longitude');

    if (latitude && longitude) {
      setEtat('Actif');
      setDate_deploiement(new Date().toISOString()); // get current system date in ISO format
    } else {
      setEtat('Pending');
      setDate_deploiement(''); // reset the deployed date value
    }
  }, [watch('latitude'), watch('longitude')]);

  //
  // const [selectedVille, setSelectedVille] = useState<IZone | null>(null);
  // const [selectedZone, setSelectedZone] = useState<IZone | null>(null);
  const { autocompleteProps: pdcTypesAutocompleteProps } =
    useAutocomplete<IType>({
      resource: 'types',
    });
  const pdcTypes = pdcTypesAutocompleteProps.options;

  const { autocompleteProps: sectorAutocompleteProps } =
    useAutocomplete<ISector>({
      resource: `secteurs/secteur`,
    });
  const apiUrl = useApiUrl();

  const { data: sectorsData } = useCustom<ISector[]>({
    url: `${apiUrl}/secteurs/secteur`,
    method: 'get',
  });

  const secteurs = sectorsData?.data;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
  });

  type Coordinates = {
    lat: string | '';
    lng: string | '';
  };

  const [coordinates, setCoordinates] = useState<Coordinates>({
    lat: '',
    lng: '',
  });

  const handleMarkerDragEnd = (event: google.maps.MapMouseEvent) => {
    const latLng = event.latLng;
    if (latLng) {
      setCoordinates({
        lat: latLng.lat().toString(),
        lng: latLng.lng().toString(),
      });
    }
  };

  useEffect(() => {
    setValue('longitude', coordinates.lng);
    setValue('latitude', coordinates.lat);
    setValue('etat', etat);

    setValue('date_deploiement', date_deploiement);
  }, [coordinates.lng, setValue, coordinates.lat, etat, date_deploiement]);
  //
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({
            lat: latitude.toString(),
            lng: longitude.toString(),
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };
  //

  const [formData, setFormData] = useState({
    address: '',
    nature: '',
    nom_pdc: '',
    responsablePdc: '',
    secteur: '',
    type_pdc: '',
    ville: '',
    zone: '',
  });
  const handleFormChange = (field: any, value: any) => {
    setFormData({ ...formData, [field]: value });
  };
  //

  const { id } = useParams();
  const [record, setRecord] = useState<IBin | null>(null);
  useEffect(() => {
    const fetchRecord = async () => {
      try {
        const response = await fetch(`/api/bins/${id}`);
        const data = await response.json();
        setRecord(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRecord();
  }, [id, setValue]);

  //
  const renderFormByStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <Box
              component="div"
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                bgcolor: '#fff',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Stack
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                  gap: 1.5,
                }}
              >
                {/* Type PDC */}
                <FormControl fullWidth>
                  <FormLabel
                    required
                    sx={{
                      marginBottom: '8px',
                      fontFamily: 'Inter',
                      fontWeight: '700',
                      fontSize: '14px',
                      color: 'text.primary',
                    }}
                  >
                    {t('bins.fields.typePDC')}
                  </FormLabel>
                  <Controller
                    control={control}
                    name="typePdcId"
                    rules={{
                      required: 'PDC Type required',
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        size="medium"
                        {...pdcTypesAutocompleteProps}
                        {...field}
                        onChange={(_, value) => {
                          field.onChange(value?.id);
                          handleFormChange('type_pdc', value?.type);
                          setRecord((prev) => ({
                            ...prev,
                            type_pdc:
                              value === null ? undefined : { id: value?.id },
                          }));
                        }}
                        getOptionLabel={(item) => {
                          return item.type ? item.type : '';
                        }}
                        value={
                          pdcTypes.find(
                            (type) => type.id === record?.type_pdc?.id
                          ) || null
                        }
                        isOptionEqualToValue={(option, value) =>
                          value === undefined || option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={!!errors.type_pdc?.message}
                            required
                          />
                        )}
                      />
                    )}
                  />
                  {errors.type_pdc && (
                    <FormHelperText error>
                      {errors.type_pdc.message}
                    </FormHelperText>
                  )}
                </FormControl>
                {/* PDC Name */}
                <FormControl fullWidth>
                  <FormLabel
                    required
                    sx={{
                      marginBottom: '8px',
                      fontFamily: 'Inter',
                      fontWeight: '700',
                      fontSize: '14px',
                      color: 'text.primary',
                    }}
                  >
                    {t('bins.fields.namePDC')}
                  </FormLabel>
                  <TextField
                    {...register('nom_pdc', {
                      required: t('errors.required.field', {
                        field: 'PDC Name',
                      }),
                    })}
                    size="medium"
                    margin="none"
                    variant="outlined"
                    error={!!errors.nom_pdc?.message}
                    onChange={(event) =>
                      handleFormChange('nom_pdc', event.target.value)
                    }
                    InputProps={{
                      inputProps: {
                        style: { textTransform: 'capitalize' },
                        maxLength: 50, // maximum length of characters allowed
                        onChange: (event) => {
                          const target = event.target as HTMLInputElement;
                          target.value =
                            target.value.charAt(0).toUpperCase() +
                            target.value.slice(1);
                        },
                      },
                    }}
                  />
                  {errors.nom_pdc && (
                    <FormHelperText error>
                      {errors.nom_pdc.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <Stack
                  direction="row"
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  {/* Res PDC */}
                  <FormControl fullWidth>
                    <FormLabel
                      required
                      sx={{
                        marginBottom: '8px',
                        fontWeight: '700',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        color: 'text.primary',
                      }}
                    >
                      {t('bins.fields.resPDC')}
                    </FormLabel>
                    <TextField
                      {...register('responsablePdc', {
                        required: t('errors.required.field', {
                          field: 'PDC Res',
                        }),
                      })}
                      size="medium"
                      margin="none"
                      variant="outlined"
                      error={!!errors.responsablePdc?.message}
                      onChange={(event) =>
                        handleFormChange('responsablePdc', event.target.value)
                      }
                      InputProps={{
                        inputProps: {
                          style: { textTransform: 'capitalize' },
                          maxLength: 50, // maximum length of characters allowed
                          onChange: (event) => {
                            const target = event.target as HTMLInputElement;
                            target.value =
                              target.value.charAt(0).toUpperCase() +
                              target.value.slice(1);
                          },
                        },
                      }}
                    />
                    {errors.responsablePdc && (
                      <FormHelperText error>
                        {errors.responsablePdc.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {/* PDC Nature */}
                  <FormControl fullWidth>
                    <FormLabel
                      required
                      sx={{
                        marginBottom: '8px',
                        fontWeight: '700',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        color: 'text.primary',
                      }}
                    >
                      {t('bins.fields.nature.label')}
                    </FormLabel>
                    <Controller
                      control={control}
                      name="nature"
                      rules={{
                        required: t('errors.required.field', {
                          field: 'nature',
                        }),
                      }}
                      defaultValue={null as any}
                      render={({ field }) => (
                        <Autocomplete
                          size="medium"
                          {...field}
                          onChange={(_, value) => {
                            field.onChange(value);
                            handleFormChange('nature', value);
                          }}
                          options={['Public', 'Private']}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              error={!!errors.nature}
                              required
                            />
                          )}
                        />
                      )}
                    />
                    {errors.nature && (
                      <FormHelperText error>
                        {errors.nature.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
                {/* Address */}
                <FormControl fullWidth>
                  <FormLabel
                    required
                    sx={{
                      marginBottom: '8px',
                      fontWeight: '700',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      color: 'text.primary',
                    }}
                  >
                    {t('bins.fields.address')}
                  </FormLabel>
                  <TextField
                    {...register('adresse', {
                      required: t('errors.required.field', {
                        field: 'Address',
                      }),
                    })}
                    size="medium"
                    margin="none"
                    variant="outlined"
                    error={!!errors.adresse?.message}
                    onChange={(event) =>
                      handleFormChange('address', event.target.value)
                    }
                    InputProps={{
                      inputProps: {
                        style: { textTransform: 'capitalize' },
                        maxLength: 50, // maximum length of characters allowed
                        onChange: (event) => {
                          const target = event.target as HTMLInputElement;
                          target.value =
                            target.value.charAt(0).toUpperCase() +
                            target.value.slice(1);
                        },
                      },
                    }}
                  />
                  {errors.adresse && (
                    <FormHelperText error>
                      {errors.adresse.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
              <Stack
                direction="row"
                sx={{ width: '100%', justifyContent: 'space-between', gap: 2 }}
              >
                {/* Sector */}
                <FormControl fullWidth>
                  <FormLabel
                    required
                    sx={{
                      marginBottom: '8px',
                      fontWeight: '700',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      color: 'text.primary',
                    }}
                  >
                    {t('bins.fields.sector')}
                  </FormLabel>
                  <Controller
                    control={control}
                    name="secteurId"
                    rules={{
                      required: 'Sector required',
                    }}
                    // defaultValue={null as any}
                    render={({ field }) => (
                      <Autocomplete
                        size="medium"
                        {...sectorAutocompleteProps}
                        {...field}
                        onChange={(_, value) => {
                          field.onChange(value?.id);
                          handleFormChange('secteur', value?.secteur);
                          setRecord((prev) => ({
                            ...prev,
                            secteur:
                              value === null
                                ? undefined
                                : {
                                    id: value?.id,
                                  },
                          }));
                        }}
                        getOptionLabel={(item) => {
                          return item.secteur ? item.secteur : '';
                        }}
                        value={
                          secteurs?.find(
                            (secteur) => secteur.id === record?.secteur?.id
                          ) || null
                        }
                        isOptionEqualToValue={(option, value) =>
                          value === undefined || option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={!!errors.secteurId?.message}
                            required
                          />
                        )}
                      />
                    )}
                  />
                  {errors.secteurId && (
                    <FormHelperText error>
                      {errors.secteurId.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Box
              component="div"
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                bgcolor: '#fff',
              }}
            >
              <Stack
                direction="row"
                sx={{ width: '100%', justifyContent: 'space-between', gap: 2 }}
              >
                {/* Latitude */}
                <TextField
                  {...register('latitude', {})}
                  onChange={(event) =>
                    handleFormChange('latitude', event.target.value)
                  }
                  error={!!(errors as any)?.latitude}
                  helperText={(errors as any)?.latitude?.message}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Latitude"
                  name="latitude"
                  value={watch('latitude') || record?.latitude}
                />
                {/*Longitude */}
                <TextField
                  {...register('longitude', {
                    // required: 'This field is required',
                  })}
                  error={!!(errors as any)?.longitude}
                  helperText={(errors as any)?.longitude?.message}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Longitude"
                  name="longitude"
                  value={watch('longitude') || record?.longitude}
                  onChange={(event) => {
                    handleFormChange('longitude', event.target.value);
                  }}
                />
                <input
                  type="hidden"
                  {...register('etat')}
                  value={watch('etat')}
                />

                {etat === 'Actif' ||
                  (etat === 'Pending' && (
                    <input
                      type="hidden"
                      {...register('date_deploiement')}
                      value={date_deploiement}
                    />
                  ))}
              </Stack>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                startIcon={<LocationOnIcon />}
                onClick={getCurrentLocation}
                sx={{
                  mt: 2,
                }}
              >
                Get Location
              </Button>
              {/* Map */}
              <Box
                component="div"
                sx={{
                  width: '100%',
                  height: '50vh',
                  borderRadius: 2,
                  border: 2,
                  borderColor: '#E9E9E9',
                  mt: 2,
                }}
              >
                {isLoaded && (
                  <GoogleMap
                    center={{
                      lat: Number(coordinates.lat) || 35.6976541,
                      lng: Number(coordinates.lng) || -0.6337376,
                    }}
                    zoom={14}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                  >
                    <Marker
                      position={{
                        lat: Number(coordinates.lat) || 35.6976541,
                        lng: Number(coordinates.lng) || -0.6337376,
                      }}
                      draggable
                      onDragEnd={handleMarkerDragEnd}
                    />
                  </GoogleMap>
                )}
              </Box>
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Stack
              justifyContent="space-between"
              spacing={2}
              sx={{ width: '100%', height: '70vh' }}
            >
              {/* BinInfo */}

              {formData && (
                <>
                  <Stack
                    justifyContent="space-between"
                    spacing={1}
                    width="100%"
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Typography fontSize={14} fontWeight={500} color="#000">
                        {t('bins.fields.typePDC')} :
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontFamily="Inter"
                        fontWeight={500}
                        color="primary.main"
                      >
                        {formData?.type_pdc}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="#000"
                      >
                        {t('bins.fields.namePDC')} :
                      </Typography>
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="primary.main"
                      >
                        {formData?.nom_pdc}
                      </Typography>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="#000"
                      >
                        {t('zone.fields.city')} :
                      </Typography>
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="primary.main"
                      >
                        {formData?.ville}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="#000"
                      >
                        {t('bins.fields.sector')} :
                      </Typography>
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="primary.main"
                      >
                        {formData?.secteur}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="#000"
                      >
                        {t('bins.fields.zone')} :
                      </Typography>
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="primary.main"
                      >
                        {formData?.zone}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="#000"
                      >
                        {t('bins.fields.coordinates')} :
                      </Typography>
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="primary.main"
                      >
                        {coordinates.lat} / {coordinates.lng}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="#000"
                      >
                        {t('bins.fields.resPDC')} :
                      </Typography>
                      <Typography
                        fontFamily="Inter"
                        fontSize={14}
                        fontWeight={500}
                        color="primary.main"
                      >
                        {formData?.responsablePdc}
                      </Typography>
                    </Stack>
                  </Stack>
                </>
              )}
              {/* Map */}
              <Box
                component="div"
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 2,
                  border: 2,
                  borderColor: '#E9E9E9',
                  mt: 2,
                }}
              >
                {isLoaded && (
                  <GoogleMap
                    center={{
                      lat: Number(coordinates?.lat),
                      lng: Number(coordinates?.lng),
                    }}
                    zoom={14}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                  >
                    <Marker
                      position={{
                        lat: Number(coordinates?.lat),
                        lng: Number(coordinates?.lng),
                      }}
                    />
                  </GoogleMap>
                )}
              </Box>
            </Stack>
          </>
        );
    }
  };

  return (
    <Grid container minHeight="85vh" p={0} m={0}>
      <Grid item xs={12} md={12} lg={9} p={1}>
        <Edit
          isLoading={formLoading}
          title={
            <Header
              title={t('bins.titles.edit')}
              // description="Create and configure a new SRB."
            />
          }
          footerButtonProps={{
            sx: {
              display: 'none',
            },
          }}
          headerButtons={<></>}
          wrapperProps={{
            sx: {
              border: 2,
              borderColor: '#E9E9E9',
              borderRadius: 2,
            },
          }}
        >
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
            autoComplete="off"
          >
            {renderFormByStep(currentStep)}
          </Box>
        </Edit>
      </Grid>
      <Grid xs={12} md={12} lg={3} p={1}>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            px: 1.5,
            width: '100%',
            border: 2,
            borderColor: '#E9E9E9',
            borderRadius: 2,
            overflow: 'hidden',
            bgcolor: '#fff',
            p: 1,
          }}
        >
          <Stepper nonLinear activeStep={currentStep} alternativeLabel>
            {stepTitles.map((label, index) => (
              <Step
                key={label}
                sx={{
                  '& .MuiStepLabel-label': {
                    fontFamily: 'Inter',
                    fontSize: '18px',
                    lineHeight: '32px',
                  },
                }}
              >
                <StepButton onClick={() => gotoStep(index)}>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          <Box
            sx={{
              marginBottom: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 2,
              alignItems: 'center',
              width: '100%',
            }}
          >
            {currentStep < stepTitles.length - 1 && (
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  gotoStep(currentStep + 1);
                }}
              >
                {t('buttons.nextStep')}
              </Button>
            )}
            {currentStep > 0 && (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: '#FFF',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  color: '#000',
                }}
                onClick={() => {
                  gotoStep(currentStep - 1);
                }}
              >
                {t('buttons.previousStep')}
              </Button>
            )}
            {currentStep === stepTitles.length - 1 && (
              <SaveButton onClick={handleSubmit(onFinish)} fullWidth />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
