import React, { useState } from "react";
import {
  IResourceComponentsProps,
  HttpError,
  useTranslate,
  useNavigation,
  useApiUrl,
} from "@pankod/refine-core";
import {
  useDataGrid,
  DataGrid,
  Grid,
  DateField,
  GridColumns,
  GridActionsCellItem,
  List,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@pankod/refine-mui";

import axios, { AxiosResponse } from "axios";
import { BinStatus } from "../../components/bin/list/BinStatus";
import { BACKENDINFO, IBin } from "../../interfaces/common";

import { Header } from "components/common/Header";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
};

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case "SORT":
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      };

    case "FILTER":
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      };
    case "REFRESH":
      return {
        ...state,
        refresh: !state.refresh,
      };
    case "PAGE":
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      };
    case "PAGESIZE":
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      };
    case "ROWS":
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      };

    default:
      return state;
  }
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
export const BinsList: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const { show, edit } = useNavigation();
  const t = useTranslate();
  const [state, dispatch] = React.useReducer(reducer, initState);

  const { dataGridProps } = useDataGrid<IBin, HttpError>({
    initialPageSize: 10,
  });

  const columns = React.useMemo<GridColumns<IBin>>(
    () => [
      {
        field: "srb",
        headerName: t("bins.fields.codeSrb"),
        description: t("bins.fields.codeSrb"),
        headerAlign: "center",
        align: "center",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "createdAt",
        headerName: t("bins.fields.createdAt"),
        flex: 1,
        minWidth: 150,
        renderCell: function render({ row }) {
          return (
            <DateField
              value={row.createdAt}
              format="LL"
              sx={{ fontSize: "14px", fontFamily: "Inter" }}
            />
          );
        },
      },

      {
        field: "date_deploiement",
        headerName: t("bins.fields.deployedAt"),
        flex: 1,
        minWidth: 150,
        renderCell: function render({ row }) {
          if (row.date_deploiement) {
            return (
              <DateField
                value={row.date_deploiement}
                format="LL"
                sx={{ fontSize: "14px", fontFamily: "Inter" }}
              />
            );
          } else {
            return "";
          }
        },
      },
      {
        field: "type",
        headerName: t("bins.fields.typePDC"),
        valueGetter: ({ row }) => row.type_pdc?.type,
        flex: 1,
        minWidth: 150,
        sortable: false,
      },
      {
        field: "nom_pdc",
        headerName: t("bins.fields.namePDC"),
        valueGetter: ({ row }) => row?.nom_pdc,
        flex: 1,
        minWidth: 150,
        sortable: false,
      },

      {
        field: "secteur",
        headerName: t("bins.fields.sector"),
        valueGetter: ({ row }) => row.secteur?.secteur,
        flex: 1,
        minWidth: 150,
        sortable: false,
      },
      {
        field: "ville",
        headerName: t("bins.fields.city"),
        valueGetter: ({ row }) => row?.secteur?.ville?.ville,
        flex: 1,
        minWidth: 150,
        sortable: false,
      },

      {
        field: "zone",
        headerName: t("bins.fields.zone"),
        valueGetter: ({ row }) => row?.secteur?.ville?.zone,
        flex: 1,
        minWidth: 150,
        sortable: false,
      },
      {
        field: "status.text",
        headerName: t("bins.fields.status"),
        headerAlign: "center",
        align: "center",
        valueGetter: ({ row }) => row.etat,
        renderCell: function render({ row }) {
          return <BinStatus status={row.etat} />;
        },
        flex: 1,
        minWidth: 100,
      },

      {
        field: "actions",
        type: "actions",
        headerName: t("table.actions"),
        flex: 1,
        minWidth: 100,
        sortable: false,
        getActions: function render(rows) {
          return [
            <GridActionsCellItem
              key={1}
              label={t("buttons.edit")}
              icon={<Edit color="success" />}
              onClick={() => rows.row.id && edit("bins", rows.row.id)}
            />,

            <GridActionsCellItem
              key={2}
              label={t("buttons.delete")}
              icon={<DeleteIcon color="error" />}
              onClick={() => {
                rows.row.id && deleteOne(rows.row.id, rows?.row?.srb ?? "");
              }}
            />,
          ];
        },
      },
    ],
    [t]
  );
  const deleteOne = (id: Number, srb: string) => {
    Swal.fire({
      titleText: t("buttons.delete") + ` ${srb}  ?`,
      text:
        t("notifications.confirmDeleteP1") +
        `${srb} ? ` +
        t("notifications.confirmDeleteP2"),
      showCancelButton: true,
      cancelButtonText: t("buttons.cancel"),
      confirmButtonText: t("buttons.delete"),
      reverseButtons: true,
      customClass: {
        title: "sw-delete-title",
        cancelButton: "sw-delete-cancel",
        confirmButton: "sw-delete-confirm",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/api/bins/${id}`, { withCredentials: true })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: `${srb}` + t("notifications.deleteSuccess"),
                showConfirmButton: false,
                timer: 1500,
              });
              dispatch({ type: "REFRESH" });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${srb} ` + t("notifications.deleteError"),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${srb} ` + t("notifications.deleteError"),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  const getData = () => {
    axios
      .get(
        `${apiUrl}/bins/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`,
        { withCredentials: true }
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          dispatch({ type: "ROWS", payload: res.data });
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  React.useEffect(() => {
    getData();
  }, [state.refresh]);

  return (
    <>
      {/* <SearchBar /> */}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <List
            title={
              <Header
                title="SRB Management"
                description="Visualiser, modifier ou gérer vos SRB"
              />
            }
            wrapperProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
          >
            <DataGrid
              {...dataGridProps}
              columns={columns}
              filterModel={undefined}
              autoHeight
              onRowClick={({ id }) => {
                show("bins", id);
              }}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              sx={{
                ...dataGridProps.sx,
                "& .MuiDataGrid-row": {
                  cursor: "pointer",
                },
              }}
              sortingMode="server"
              filterMode="server"
              onFilterModelChange={(data) => {
                dispatch({ type: "FILTER", payload: data.items });
              }}
              onSortModelChange={(data) =>
                dispatch({
                  type: "SORT",
                  payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
                })
              }
              pagination
              paginationMode="server"
              onPageChange={(data) => dispatch({ type: "PAGE", payload: data })}
              onPageSizeChange={(data) =>
                dispatch({ type: "PAGESIZE", payload: data })
              }
              rows={state.rows}
              rowCount={state.total}
              page={state.page}
              pageSize={state.pageSize}
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          </List>
        </Grid>
      </Grid>
    </>
  );
};
