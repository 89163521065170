import React, { useState } from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useNavigation,
  useApiUrl,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  GridActionsCellItem,
  List,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@pankod/refine-mui";

import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios, { AxiosResponse } from "axios";
import { BACKENDINFO, ISector } from "../../../interfaces/common";
import Swal from "sweetalert2";

const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
};

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case "SORT":
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      };

    case "FILTER":
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      };
    case "REFRESH":
      return {
        ...state,
        refresh: !state.refresh,
      };
    case "PAGE":
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      };
    case "PAGESIZE":
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      };
    case "ROWS":
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      };

    default:
      return state;
  }
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export const SectorsList: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const { edit } = useNavigation();
  const t = useTranslate();
  const [state, dispatch] = React.useReducer(reducer, initState);

  const { dataGridProps } = useDataGrid<ISector>({
    initialPageSize: 10,
  });

  const columns = React.useMemo<GridColumns<ISector>>(
    () => [
      {
        field: "secteur",
        headerName: t("sector.fields.sector"),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "zone",
        headerName: t("sector.fields.zone"),
        valueGetter: ({ row }) => row?.ville?.zone,
        flex: 1,
        minWidth: 300,
      },
      {
        field: "ville",
        headerName: t("sector.fields.city"),
        valueGetter: ({ row }) => row?.ville?.ville,
        flex: 1,
        minWidth: 300,
      },

      {
        field: "actions",
        headerName: t("table.actions"),
        type: "actions",
        getActions: function render(rows) {
          return [
            <GridActionsCellItem
              key={1}
              label={t("buttons.edit")}
              icon={<Edit color="success" />}
              onClick={() => rows.row.id && edit("secteurs", rows.row.id)}
            />,

            <GridActionsCellItem
              key={2}
              label={t("buttons.delete")}
              icon={<DeleteIcon color="error" />}
              onClick={() => {
                rows.row.id && deleteOne(rows.row.id, rows?.row?.secteur ?? "");
              }}
            />,
          ];
        },
      },
    ],
    [t]
  );
  const deleteOne = (id: Number, secteur: string) => {
    Swal.fire({
      titleText: t("buttons.delete") + ` ${secteur}  ?`,
      text:
        t("notifications.confirmDeleteP1") +
        `${secteur} ? ` +
        t("notifications.confirmDeleteP2"),
      showCancelButton: true,
      cancelButtonText: t("buttons.cancel"),
      confirmButtonText: t("buttons.delete"),
      reverseButtons: true,
      customClass: {
        title: "sw-delete-title",
        cancelButton: "sw-delete-cancel",
        confirmButton: "sw-delete-confirm",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/api/secteurs/${id}`, { withCredentials: true })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: `${secteur} ` + t("notifications.deleteSuccess"),
                showConfirmButton: false,
                timer: 1500,
              });

              dispatch({ type: "REFRESH" });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${secteur} ` + t("notifications.deleteError"),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${secteur} ` + t("notifications.deleteError"),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };
  // Data Grid and Filters

  const getData = () => {
    axios
      .get(
        `${apiUrl}/secteurs/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`,
        { withCredentials: true }
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          dispatch({ type: "ROWS", payload: res.data });
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  React.useEffect(() => {
    getData();
  }, [state.refresh]);

  return (
    <List
      wrapperProps={{
        sx: {
          paddingX: { xs: 2, md: 0 },
          border: 2,
          borderColor: "#E9E9E9",
          borderRadius: 2,
        },
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        filterModel={undefined}
        autoHeight
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        density="comfortable"
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={(model, details) => {
          dispatch({ type: "FILTER", payload: model.items });
          dataGridProps.onFilterModelChange(model, details);
        }}
        onSortModelChange={(data) =>
          dispatch({
            type: "SORT",
            payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
          })
        }
        pagination
        paginationMode="server"
        onPageChange={(data) => dispatch({ type: "PAGE", payload: data })}
        onPageSizeChange={(data) =>
          dispatch({ type: "PAGESIZE", payload: data })
        }
        rows={state.rows}
        rowCount={state.total}
        page={state.page}
        pageSize={state.pageSize}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </List>
  );
};
