import React, { useEffect, useState } from 'react';
import {
  Edit,
  Box,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@pankod/refine-mui';
import { useForm, Controller } from '@pankod/refine-react-hook-form';
import {
  useTranslate,
  IResourceComponentsProps,
  HttpError,
} from '@pankod/refine-core';
import { IZone } from 'interfaces/common';
import { States } from 'constants/index';
import { useParams } from '@pankod/refine-react-router-v6';

export const ZoneEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm<IZone, HttpError, IZone>();
  const { id } = useParams();
  const [record, setRecord] = useState<IZone | null>(null);
  useEffect(() => {
    const fetchRecord = async () => {
      try {
        const response = await fetch(`/api/villes/ville/${id}`);
        const data = await response.json();
        setRecord(data);
        setValue('zone', data?.zone);
        setValue('ville', data?.ville);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRecord();
  }, [id, setValue]);

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      wrapperProps={{
        sx: {
          border: 2,
          borderColor: '#E9E9E9',
          borderRadius: 2,
        },
      }}
    >
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        autoComplete="off"
      >
        {/* Zone Name */}
        <FormControl fullWidth>
          <FormLabel
            required
            sx={{
              marginBottom: '8px',
              fontFamily: 'Inter',
              fontWeight: '700',
              fontSize: '14px',
              color: 'text.primary',
            }}
          >
            {t('zone.fields.zone')}
          </FormLabel>
          <TextField
            {...register('zone', {
              required: t('errors.required.field', {
                field: 'zone',
              }),
            })}
            size="medium"
            name="zone"
            margin="none"
            variant="outlined"
            error={!!errors.zone?.message}
            defaultValue={record?.zone || ''}
            InputProps={{
              inputProps: {
                style: { textTransform: 'uppercase' },
                maxLength: 50,
                onChange: (event) => {
                  const target = event.target as HTMLInputElement;

                  target.value = target.value.toUpperCase();
                },
              },
            }}
          />
          {errors.zone && (
            <FormHelperText error>{errors.zone.message}</FormHelperText>
          )}
        </FormControl>

        {/* Ville */}
        <FormControl fullWidth>
          <FormLabel
            required
            sx={{
              marginBottom: '8px',
              fontFamily: 'Inter',
              fontWeight: '700',
              fontSize: '14px',
              color: 'text.primary',
            }}
          >
            {t('sector.fields.city')}
          </FormLabel>
          <Controller
            control={control}
            name="ville"
            rules={{
              required: 'City required',
            }}
            render={({ field }) => (
              <Autocomplete
                size="medium"
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                options={States.map((state) => state.state_name)}
                // }
                renderInput={(params) => (
                  <TextField
                    {...register('ville', {
                      required: t('errors.required.field', {
                        field: 'ville',
                      }),
                    })}
                    {...params}
                    variant="outlined"
                    error={!!errors.ville?.message}
                    required
                  />
                )}
              />
            )}
          />
          {errors.ville && (
            <FormHelperText error>{errors.ville.message}</FormHelperText>
          )}
        </FormControl>
      </Box>
    </Edit>
  );
};
