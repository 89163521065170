import React from 'react';
import {
  Edit,
  Box,
  TextField,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@pankod/refine-mui';
import { useForm } from '@pankod/refine-react-hook-form';
import {
  useTranslate,
  IResourceComponentsProps,
  HttpError,
} from '@pankod/refine-core';
import { IType } from 'interfaces/common';

export const TypeEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,

    formState: { errors },
  } = useForm<IType, HttpError, IType>();
  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      wrapperProps={{
        sx: {
          border: 2,
          borderColor: '#E9E9E9',
          borderRadius: 2,
        },
      }}
    >
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        autoComplete="off"
      >
        {/* PDC TYPE */}
        <FormControl fullWidth>
          <FormLabel
            required
            sx={{
              marginBottom: '8px',
              fontFamily: 'Inter',
              fontWeight: '700',
              fontSize: '14px',
              color: 'text.primary',
            }}
          >
            {t('pdc.fields.pdc')}
          </FormLabel>
          <TextField
            {...register('type', {
              required: t('errors.required.field', {
                field: 'type',
              }),
            })}
            size="medium"
            margin="none"
            variant="outlined"
            error={!!errors.type?.message}
            InputProps={{
              inputProps: {
                style: { textTransform: 'capitalize' },
                maxLength: 50, // maximum length of characters allowed
                onChange: (event) => {
                  const target = event.target as HTMLInputElement;
                  target.value =
                    target.value.charAt(0).toUpperCase() +
                    target.value.slice(1);
                },
              },
            }}
          />
          {errors.type && (
            <FormHelperText error>{errors.type.message}</FormHelperText>
          )}
        </FormControl>
      </Box>
    </Edit>
  );
};
