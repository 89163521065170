import React, { useState } from 'react';
import {
  Create,
  Box,
  useAutocomplete,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@pankod/refine-mui';
import { useForm, Controller } from '@pankod/refine-react-hook-form';
import {
  useTranslate,
  IResourceComponentsProps,
  HttpError,
} from '@pankod/refine-core';
import { ISector, IZone } from 'interfaces/common';

export const SectorCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    control,
    formState: { errors },
  } = useForm<ISector, HttpError, ISector>();
  const [selectedVille, setSelectedVille] = useState<IZone | null>(null);
  const { autocompleteProps: villeAutocompleteProps } = useAutocomplete<IZone>({
    resource: 'villes/villes',
  });
  const { autocompleteProps: zoneAutocompleteProps } = useAutocomplete<IZone>({
    resource: `villes/${selectedVille?.ville}`,
  });

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      wrapperProps={{
        sx: {
          border: 2,
          borderColor: '#E9E9E9',
          borderRadius: 2,
        },
      }}
    >
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        autoComplete="off"
      >
        {/* Sector Name */}
        <FormControl fullWidth>
          <FormLabel
            required
            sx={{
              marginBottom: '8px',
              fontFamily: 'Inter',
              fontWeight: '700',
              fontSize: '14px',
              color: 'text.primary',
            }}
          >
            {t('sector.fields.sector')}
          </FormLabel>
          <TextField
            {...register('secteur', {
              required: t('errors.required.field', {
                field: 'Sector Name',
              }),
            })}
            size="medium"
            margin="none"
            variant="outlined"
            error={!!errors.secteur?.message}
            InputProps={{
              inputProps: {
                style: { textTransform: 'capitalize' },
                maxLength: 50,
                onChange: (event) => {
                  const target = event.target as HTMLInputElement;
                  target.value =
                    target.value.charAt(0).toUpperCase() +
                    target.value.slice(1);
                },
              },
            }}
          />
          {errors.secteur && (
            <FormHelperText error>{errors.secteur.message}</FormHelperText>
          )}
        </FormControl>

        {/* Ville */}

        <FormControl fullWidth>
          <FormLabel
            required
            sx={{
              marginBottom: '8px',
              fontFamily: 'Inter',
              fontWeight: '700',
              fontSize: '14px',
              color: 'text.primary',
            }}
          >
            {t('zone.fields.city')}
          </FormLabel>
          <Controller
            control={control}
            name="villeId"
            rules={{
              required: 'City is required',
            }}
            render={({ field }) => (
              <Autocomplete
                size="medium"
                {...villeAutocompleteProps}
                {...field}
                onChange={(_, value) => {
                  field.onChange(value?.id);
                  setSelectedVille(value);
                }}
                getOptionLabel={(item) => {
                  return item.ville ? item.ville : '';
                }}
                isOptionEqualToValue={(option, value) =>
                  value === undefined || option.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // error={!!errors.type_pdc?.message}
                    required
                  />
                )}
              />
            )}
          />
          {errors.villeId && (
            <FormHelperText error>{errors.villeId.message}</FormHelperText>
          )}
        </FormControl>
        {/* Zone */}
        <FormControl fullWidth>
          <FormLabel
            required
            sx={{
              marginBottom: '8px',
              fontFamily: 'Inter',
              fontWeight: '700',
              fontSize: '14px',
              color: 'text.primary',
            }}
          >
            {t('zone.fields.zone')}
          </FormLabel>
          <Controller
            control={control}
            name="villeId"
            rules={{
              required: 'City is required',
            }}
            render={({ field }) => (
              <Autocomplete
                size="medium"
                {...zoneAutocompleteProps}
                {...field}
                onChange={(_, value) => {
                  field.onChange(value?.id);
                }}
                getOptionLabel={(item) => {
                  return item.zone ? item.zone : '';
                }}
                isOptionEqualToValue={(option, value) =>
                  value === undefined || option.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // error={!!errors.type_pdc?.message}
                    required
                  />
                )}
              />
            )}
          />
          {errors.villeId && (
            <FormHelperText error>{errors.villeId.message}</FormHelperText>
          )}
        </FormControl>
      </Box>
    </Create>
  );
};
