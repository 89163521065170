import { Stack, Typography } from '@pankod/refine-mui';
import React from 'react';
interface IHeader {
  title?: string;
  subTitle?: string;
  description?: string;
}

export const Header = ({ title, description, subTitle }: IHeader) => {
  return (
    <Stack>
      <Stack direction="row" gap={2}>
        <Typography
          fontFamily="Inter"
          fontSize={24}
          fontWeight={700}
          color="#000"
          sx={{ textTransform: 'uppercase' }}
        >
          {title}
        </Typography>
        {/* <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ backgroundColor: '#000', width: 2 }}
        /> */}
        <Typography
          fontFamily="Inter"
          fontSize={24}
          fontWeight={700}
          color="#000"
          sx={{ textTransform: 'uppercase' }}
        >
          {subTitle}
        </Typography>
      </Stack>
      <Typography
        fontFamily="Inter"
        fontSize={14}
        fontWeight={500}
        color="#A9A9A9"
      >
        {description}
      </Typography>
    </Stack>
  );
};
