import { useTranslate } from '@pankod/refine-core';
import { Chip, ChipProps, useTheme } from '@pankod/refine-mui';

type BinStatusProps = {
  status?: 'Actif' | 'Pending';
};

export const BinStatus: React.FC<BinStatusProps> = ({ status }) => {
  const t = useTranslate();

  let color: ChipProps['color'];

  switch (status) {
    case 'Actif':
      color = 'success';
      break;
    case 'Pending':
      color = 'warning';
      break;
  }

  return (
    <Chip
      variant="filled"
      size="small"
      color={color}
      label={t(`enum.binStatuses.${status}`)}
    />
  );
};
