import { AuthProvider } from "@pankod/refine-core";
import axios from "axios";
import Swal from "sweetalert2";
export const TOKEN_KEY = "refine-auth";

export const authProvider: AuthProvider = {
  login: async ({ email, password }: any) => {
    await axios
      .post(
        "/api/users/login",
        {
          email,
          password,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
          // window.location.href = "https://admin.polyrec-app.com/";
          return Promise.resolve();
        }
        if (res.status === 401) {
          Swal.fire("Erreur", "mauvaise combinaison email/password", "error");
          return Promise.reject(
            new Error("mauvaise combinaison email/password")
          );
        }
      })
      .catch((e) => {
        console.log(e);
        // Swal.fire('Erreur', 'mauvaise 2 combinaison email/password', 'error');
        return Promise.reject(new Error("error login"));
      });
  },
  logout: async () => {
    const res = await axios.get("/api/users/logout", {
      withCredentials: true,
    });
    if (res.status === 200) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },
  checkError: () => Promise.resolve(),
  checkAuth: async () => {
    // const res = await axios.get('/users/isAuth', {
    //   withCredentials: true,
    // });
    const res = await axios.get("/api/users/isAuth", {
      withCredentials: true,
    });
    if (res.status === 200) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const res = await axios.get("/api/users/isAuth", {
      withCredentials: true,
    });
    if (res.status === 200) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject();
    }
  },
};
