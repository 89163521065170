import React, { useEffect, useState } from "react";

import { Refine } from "@pankod/refine-core";

import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-mui";
import { PeopleAlt } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { ReactComponent as SmallBin } from "./assets/icons/smallBin.svg";

import dataProvider from "@pankod/refine-simple-rest";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { Title, Sider, Layout, Header } from "components/layout";
import { authProvider } from "./authProvider";
import { customTheme } from "./theme";
import { Dashboard } from "pages/dashboard/Dashboard";
import { Analytics } from "pages/analytics/Analytics";
import { BinCreate, BinEdit, BinShow, BinsList } from "pages/bins";
import axios from "axios";
import { Login } from "pages/auth/Login";
import { SectorsList, SectorCreate, SectorEdit } from "pages/settings/sector";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  IntegratorCreate,
  IntegratorEdit,
  IntegratorsList,
} from "pages/users/integrator";
import { TypesList, TypeCreate, TypeEdit } from "pages/settings/pdcTypes";

import { ZoneCreate, ZoneEdit, ZonesList } from "pages/settings/zone";
import {
  CoordinatorsList,
  CoordinatorCreate,
  CoordinatorEdit,
} from "pages/users/coordinator";
import { DriversList, DriverCreate, DriverEdit } from "pages/users/driver";
import { IUser } from "interfaces/common";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const axiosInstance = axios.create();
  axiosInstance.defaults.withCredentials = true;

  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    axios
      .get("/api/users/isAuth", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data);
          setloading(false);
        } else {
          setUser(null);
          setloading(false);
        }
      })
      .catch((e) => {
        setUser(null);
        setloading(false);
      });
  }, []);

  if (user?.role === "Admin") {
    return (
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <RefineSnackbarProvider>
          <Refine
            dataProvider={dataProvider(
              "https://polyrec-app.com/api",

              axiosInstance
            )}
            notificationProvider={notificationProvider}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            resources={[
              {
                name: "bins",
                options: { label: t("labels.bin") },
                list: BinsList,
                show: BinShow,
                create: BinCreate,
                edit: BinEdit,
                icon: <SmallBin width="" />,
              },
              {
                name: "historiques",
                list: Analytics,
                // options: { label: t('labels.user') },
                options: { label: "Analytics" },
                icon: <TrendingUpIcon />,
              },
              //  {name :'requests'} ,

              {
                name: "Users",
                options: { label: t("labels.user") },
                icon: <PeopleAlt />,
              },

              {
                name: "integrateurs",
                options: { label: t("labels.integrator") },
                parentName: "Users",
                list: IntegratorsList,

                create: IntegratorCreate,
                edit: IntegratorEdit,
                icon: <PeopleAlt />,
              },
              {
                name: "coordinateurs",
                options: { label: t("labels.coordinator") },
                parentName: "Users",
                list: CoordinatorsList,

                create: CoordinatorCreate,
                edit: CoordinatorEdit,
                icon: <PeopleAlt />,
              },
              {
                name: "drivers",
                options: { label: t("labels.driver") },
                parentName: "Users",
                list: DriversList,

                create: DriverCreate,
                edit: DriverEdit,
                icon: <PeopleAlt />,
              },
              {
                name: "Settings",
                options: { label: t("labels.settings") },
                icon: <SettingsIcon />,
              },
              {
                name: "secteurs",
                options: { label: t("labels.sector") },
                parentName: "Settings",
                list: SectorsList,

                create: SectorCreate,
                edit: SectorEdit,
                // canDelete: true,
                icon: <LocationOnIcon />,
              },

              {
                name: "villes",
                options: { label: t("labels.zone") },
                parentName: "Settings",
                list: ZonesList,

                create: ZoneCreate,
                edit: ZoneEdit,
                // canDelete: true,
                icon: <LocationOnIcon />,
              },
              {
                name: "types",
                options: { label: t("labels.pdc") },
                parentName: "Settings",
                list: TypesList,

                create: TypeCreate,
                edit: TypeEdit,
                // canDelete: true,
                icon: <LocationCityIcon />,
              },
            ]}
            Title={Title}
            Sider={Sider}
            Layout={Layout}
            Header={Header}
            routerProvider={routerProvider}
            authProvider={authProvider}
            LoginPage={Login}
            DashboardPage={Dashboard}
            i18nProvider={i18nProvider}
          />
        </RefineSnackbarProvider>
      </ThemeProvider>
    );
  } else if (user?.role === "Coordinateur") {
    return (
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <RefineSnackbarProvider>
          <Refine
            dataProvider={dataProvider(
              "https://polyrec-app.com/api",
              axiosInstance
            )}
            notificationProvider={notificationProvider}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            resources={[
              {
                name: "bins",
                options: { label: "Bin" },
                list: BinsList,
                show: BinShow,
                create: BinCreate,
                edit: BinEdit,
                icon: <SmallBin width="" />,
              },

              //  {name :'requests'} ,

              {
                name: "Users",
                icon: <PeopleAlt />,
              },
              {
                name: "drivers",
                options: { label: "Driver " },
                parentName: "Users",
                list: DriversList,

                create: DriverCreate,
                edit: DriverEdit,
                icon: <PeopleAlt />,
              },
              {
                name: "Settings",
                icon: <SettingsIcon />,
              },
              {
                name: "secteurs",
                options: { label: "Sector " },
                parentName: "Settings",
                list: SectorsList,

                create: SectorCreate,
                edit: SectorEdit,
                // canDelete: true,
                icon: <LocationOnIcon />,
              },

              {
                name: "villes",
                options: { label: "Zone " },
                parentName: "Settings",
                list: ZonesList,

                create: ZoneCreate,
                edit: ZoneEdit,
                // canDelete: true,
                icon: <LocationOnIcon />,
              },
              {
                name: "types",
                options: { label: "PDC  " },
                parentName: "Settings",
                list: TypesList,

                create: TypeCreate,
                edit: TypeEdit,
                // canDelete: true,
                icon: <LocationCityIcon />,
              },
            ]}
            Title={Title}
            Sider={Sider}
            Layout={Layout}
            Header={Header}
            routerProvider={routerProvider}
            authProvider={authProvider}
            LoginPage={Login}
            DashboardPage={Dashboard}
            i18nProvider={i18nProvider}
          />
        </RefineSnackbarProvider>
      </ThemeProvider>
    );
  } else if (user?.role === "Integrateur") {
    return (
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <RefineSnackbarProvider>
          <Refine
            dataProvider={dataProvider(
              "https://polyrec-app.com/api",

              axiosInstance
            )}
            notificationProvider={notificationProvider}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            resources={[
              {
                name: "bins",
                options: { label: "Bin" },
                list: BinsList,
                show: BinShow,
                create: BinCreate,
                edit: BinEdit,
                icon: <SmallBin width="" />,
              },

              //  {name :'requests'} ,

              {
                name: "Users",
                icon: <PeopleAlt />,
              },

              {
                name: "coordinateurs",
                options: { label: "Coordinator " },
                parentName: "Users",
                list: CoordinatorsList,

                create: CoordinatorCreate,
                edit: CoordinatorEdit,
                icon: <PeopleAlt />,
              },
              {
                name: "drivers",
                options: { label: "Driver " },
                parentName: "Users",
                list: DriversList,

                create: DriverCreate,
                edit: DriverEdit,
                icon: <PeopleAlt />,
              },
              {
                name: "Settings",
                icon: <SettingsIcon />,
              },
              {
                name: "secteurs",
                options: { label: "Sector " },
                parentName: "Settings",
                list: SectorsList,

                create: SectorCreate,
                edit: SectorEdit,
                // canDelete: true,
                icon: <LocationOnIcon />,
              },

              {
                name: "villes",
                options: { label: "Zone " },
                parentName: "Settings",
                list: ZonesList,

                create: ZoneCreate,
                edit: ZoneEdit,
                // canDelete: true,
                icon: <LocationOnIcon />,
              },
              {
                name: "types",
                options: { label: "PDC  " },
                parentName: "Settings",
                list: TypesList,

                create: TypeCreate,
                edit: TypeEdit,
                // canDelete: true,
                icon: <LocationCityIcon />,
              },
            ]}
            Title={Title}
            Sider={Sider}
            Layout={Layout}
            Header={Header}
            routerProvider={routerProvider}
            authProvider={authProvider}
            LoginPage={Login}
            DashboardPage={Dashboard}
            i18nProvider={i18nProvider}
          />
        </RefineSnackbarProvider>
      </ThemeProvider>
    );
  } else {
    return (
      <>
        <ThemeProvider theme={customTheme}>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <Refine
              dataProvider={dataProvider(
                "https://polyrec-app.com/api",

                axiosInstance
              )}
              notificationProvider={notificationProvider}
              ReadyPage={ReadyPage}
              catchAll={<ErrorComponent />}
              resources={[
                {
                  name: "Settings",
                  icon: <SettingsIcon />,
                },
              ]}
              Title={Title}
              Sider={Sider}
              Layout={Layout}
              Header={Header}
              routerProvider={routerProvider}
              authProvider={authProvider}
              LoginPage={Login}
              DashboardPage={Dashboard}
              i18nProvider={i18nProvider}
            />
          </RefineSnackbarProvider>
        </ThemeProvider>
      </>
    );
  }
}

export default App;
