import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@pankod/refine-mui';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { logo_polyrec, background_web, logo_kb } from 'assets';
import { useLogin } from '@pankod/refine-core';

type LoginVariables = {
  email: string;
  password: string;
};
export const Login: React.FC = () => {
  const { mutate: login } = useLogin<LoginVariables>();
  const [states, setStates] = useState({
    email: '',
    password: '',
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStates({ ...states, [event?.target?.name]: event?.target?.value });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({ ...states });
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  //
  const [currentView, setCurrentView] = useState('SpalshScreen');
  const [elapsedTime, setElapsedTime] = useState(0);

  // Set the elapsed time to 0 when the component mounts
  React.useEffect(() => {
    setElapsedTime(0);
  }, []);

  // Update the elapsed time every second
  React.useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
    }, 1000);

    // Clean up the timer when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, []);

  // Switch to the MainScreen after 5 seconds
  React.useEffect(() => {
    if (elapsedTime >= 4) {
      setCurrentView('MainScreen');
    }
  }, [elapsedTime]);

  // SplashScreen
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 25
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const renderView = () => {
    switch (currentView) {
      case 'SpalshScreen':
        return (
          <Box component="div">
            <Container
              component="main"
              maxWidth="xs"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              <Stack
                spacing={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mx: 6,
                  gap: 8,
                }}
              >
                <img src={logo_polyrec} alt="Polyrec Logo" />
                <div>
                  <CircularProgress
                    // variant="determinate"
                    // value={progress}
                    color="primary"
                    size={60}
                  />
                </div>
              </Stack>
              <Box
                sx={{
                  mt: 28,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {/* <img src={logo_kb} alt="kb Logo" /> */}
                <Typography
                  fontFamily="Inter"
                  fontSize={10}
                  fontWeight={600}
                  color="#BBBBBB"
                  textAlign="center"
                  sx={{
                    textTransform: 'uppercase',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      fontFamily: 'Inter',
                    }}
                  >
                    Designed by
                  </span>{' '}
                  ABC,{' '}
                  <span
                    style={{
                      fontWeight: 400,
                      fontFamily: 'Inter',
                    }}
                  >
                    Developed by
                  </span>{' '}
                  KB, <br />
                  <span
                    style={{
                      fontWeight: 400,
                      fontFamily: 'Inter',
                    }}
                  >
                    all rights reserved
                  </span>{' '}
                  POLYREC
                </Typography>
              </Box>
            </Container>
          </Box>
        );
      case 'MainScreen':
        return (
          <Box
            component="div"
            sx={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: `url(${background_web})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundColor: '#fff',
              gap: 6,
            }}
          >
            <Paper
              elevation={2}
              sx={{
                width: 600,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                py: 6,
                borderRadius: '50px',
              }}
            >
              <Box
                sx={{
                  width: 300,
                  height: 120,
                  mb: 4,
                }}
              >
                <img src={logo_polyrec} alt="PolyRec Logo" />
              </Box>

              <Stack
                direction="column"
                spacing={3}
                sx={{
                  width: 500,
                  maxWidth: '100%',
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(e as any);
                  }
                }}
              >
                <TextField
                  required
                  id="outlined-required"
                  name="email"
                  type={'text'}
                  color="primary"
                  label="E-mail"
                  variant="outlined"
                  placeholder="E-mail"
                  onChange={handleChange}
                />

                {/* PassWord */}
                <FormControl variant="outlined" required>
                  <InputLabel htmlFor="outlined-start-adornment">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-start-adornment"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    name="password"
                    sx={{ backgroundColor: 'white' }}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  sx={{
                    height: 50,
                  }}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  Login
                </Button>
              </Stack>
            </Paper>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {/* <img src={logo_kb} alt="kb Logo" /> */}
              <Typography
                fontFamily="Inter"
                fontSize={10}
                fontWeight={600}
                color="#BBBBBB"
                textAlign="center"
                sx={{
                  textTransform: 'uppercase',
                }}
              >
                <span
                  style={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                  }}
                >
                  Designed by
                </span>{' '}
                ABC,{' '}
                <span
                  style={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                  }}
                >
                  Developed by
                </span>{' '}
                KB, <br />
                <span
                  style={{
                    fontWeight: 400,
                    fontFamily: 'Inter',
                  }}
                >
                  all rights reserved
                </span>{' '}
                POLYREC
              </Typography>
            </Box>
          </Box>
        );
    }
  };

  return <div style={{ height: '100vh' }}>{renderView()}</div>;
};
