import React from 'react';
import {
  useGetIdentity,
  useGetLocale,
  useSetLocale,
} from '@pankod/refine-core';
import {
  AppBar,
  Avatar,
  Stack,
  FormControl,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@pankod/refine-mui';

import i18n from 'i18n';

export const Header: React.FC = () => {
  const changeLanguage = useSetLocale();
  const locale = useGetLocale();
  const currentLocale = locale();

  const { data: user } = useGetIdentity();

  const showUserInfo = user && (user.email || user.avatar);

  return (
    <AppBar color="default" position="sticky" elevation={0}>
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
        >
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              disableUnderline
              defaultValue={currentLocale}
              inputProps={{ 'aria-label': 'Without label' }}
              variant="standard"
            >
              {[...(i18n.languages ?? [])].sort().map((lang: string) => (
                <MenuItem
                  selected={currentLocale === lang}
                  key={lang}
                  defaultValue={lang}
                  onClick={() => {
                    changeLanguage(lang);
                  }}
                  value={lang}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Avatar
                      sx={{
                        width: '16px',
                        height: '16px',
                        marginRight: '5px',
                      }}
                      src={`/images/flags/${lang}.svg`}
                    />
                    {lang === 'en' ? 'English' : 'français'}
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showUserInfo && (
            <Stack direction="row" gap="16px" alignItems="center">
              {/* <Avatar>{user.email.charAt(user.email.indexOf('@') + 1)}</Avatar> */}
              {user.email && (
                <Typography variant="subtitle2" fontFamily="Inter">
                  {' '}
                  {user.email.substring(0, user.email.indexOf('@'))}
                </Typography>
              )}
              <Avatar>
                {user.email.slice(0, user.email.indexOf('@'))[0].toUpperCase()}
              </Avatar>
            </Stack>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
