import React, { useState } from 'react';

import {
  Box,
  Button,
  Grid,
  LinearProgress,
  LinearProgressProps,
  Paper,
  Stack,
  Typography,
} from '@pankod/refine-mui';
import {
  useList,
  HttpError,
  useNavigation,
  useTranslate,
} from '@pankod/refine-core';
import { IBin } from 'interfaces/common';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { bin_marker_blue, bin_marker_red, bin_marker_orange } from 'assets';

//

interface LinearProgressWithLabelProps extends LinearProgressProps {
  value: number;
}
function getColor(value: number): 'primary' | 'warning' | 'secondary' {
  if (value <= 50) {
    return 'primary';
  } else if (value <= 70) {
    return 'warning';
  } else {
    return 'secondary';
  }
}
function LinearProgressWithLabel(props: LinearProgressWithLabelProps) {
  const { value } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          sx={{ color: getColor(value), height: '6px' }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          fontFamily="Inter"
          variant="body2"
          color="text.secondary"
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}
//
export const Dashboard = () => {
  const { show } = useNavigation();
  const { data } = useList<IBin, HttpError>({
    resource: 'bins/bin',
  });

  const binDetails = data?.data;
  const t = useTranslate();

  function getIcon(percentage: number): string {
    if (percentage <= 50) {
      return bin_marker_blue;
    } else if (percentage > 70) {
      return bin_marker_red;
    } else {
      return bin_marker_orange;
    }
  }
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
  });
  const [selectedBinDetails, setSelectedBinDetails] = useState<IBin | null>();

  const handleMarkerClick = (bin?: IBin) => {
    setSelectedBinDetails(bin);
  };

  const level =
    (selectedBinDetails?.citizens_requests
      ?.map((fill) => fill.percentage)
      ?.pop() as number) || 0;

  return (
    // The Dashboard component needs some refining
    <>
      {/* autocomplete Search (SRB) */}

      <Grid container minHeight="85vh" p={0} m={0}>
        <Grid item xs={12} md={12} lg={9} p={2}>
          <Box
            component="div"
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: 2,
              border: 2,
              borderColor: '#E9E9E9',
              overflow: 'hidden',
            }}
          >
            <>
              {isLoaded && (
                <GoogleMap
                  center={{
                    lat: 35.6976541,
                    lng: -0.6337376,
                  }}
                  zoom={14}
                  mapContainerStyle={{
                    width: '100%',
                    height: '100%',
                  }}
                  options={{
                    streetViewControl: false,
                    mapId: '80d3b0a42b8e1d11',
                  }}
                >
                  {binDetails?.map((bin) => {
                    const percentages = bin?.citizens_requests?.map(
                      (request) => request.percentage
                    );
                    const lastPercentage = percentages?.pop() || 0;

                    return (
                      <Marker
                        key={bin.id}
                        position={{
                          lat: Number(bin?.latitude),
                          lng: Number(bin?.longitude),
                        }}
                        icon={getIcon(lastPercentage)}
                        onClick={() => handleMarkerClick(bin)}
                      >
                        {selectedBinDetails === bin && (
                          <InfoWindow
                            // position={{
                            //   lat: Number(bin?.latitude) + 0.0002,
                            //   lng: Number(bin?.longitude),
                            // }}
                            onCloseClick={() => false}
                          >
                            <div>
                              <Typography fontFamily="Inter">{`Percentage: ${level}%`}</Typography>
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                    );
                  })}
                </GoogleMap>
              )}
            </>
          </Box>
        </Grid>
        <Grid xs={12} md={12} lg={3}>
          <Stack spacing={2} p={2}>
            {/* InfoCard */}
            <Paper elevation={2}>
              {/* Maping the Bin Info Goes here */}
              <Stack spacing={2} p={2}>
                {/* Bin */}
                <Stack direction="row" gap={2}>
                  <Typography
                    fontFamily="Inter"
                    fontSize={16}
                    fontWeight={500}
                    color="#000"
                  >
                    SRB :
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize={16}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {selectedBinDetails?.srb ? selectedBinDetails?.srb : '#'}
                  </Typography>
                </Stack>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <LinearProgressWithLabel
                    value={level}
                    color={getColor(level)}
                  />
                </Box>
                {/* BinInfo */}

                <Stack justifyContent="space-between" gap={2}>
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="#000"
                    >
                      {t('bins.fields.typePDC')} :
                    </Typography>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="primary.main"
                    >
                      {selectedBinDetails?.type_pdc?.type
                        ? selectedBinDetails?.type_pdc?.type
                        : '#'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="#000"
                    >
                      {t('bins.fields.createdAt')} :
                    </Typography>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="primary.main"
                    >
                      {selectedBinDetails?.createdAt
                        ? selectedBinDetails?.createdAt.substring(0, 10)
                        : '#'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="#000"
                    >
                      {t('bins.fields.deployedAt')} :
                    </Typography>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="primary.main"
                    >
                      {selectedBinDetails?.date_deploiement
                        ? selectedBinDetails?.date_deploiement.substring(0, 10)
                        : '#'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="#000"
                    >
                      {t('zone.fields.city')} :
                    </Typography>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="primary.main"
                    >
                      {selectedBinDetails?.secteur?.ville?.ville
                        ? selectedBinDetails?.secteur?.ville.ville
                        : '#'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="#000"
                    >
                      {t('bins.fields.sector')} :
                    </Typography>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="primary.main"
                    >
                      {selectedBinDetails?.secteur?.secteur
                        ? selectedBinDetails?.secteur?.secteur
                        : '#'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="#000"
                    >
                      {t('bins.fields.zone')} :
                    </Typography>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="primary.main"
                    >
                      {selectedBinDetails?.secteur?.ville?.zone
                        ? selectedBinDetails?.secteur?.ville.zone
                        : '#'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="#000"
                    >
                      {t('bins.fields.coordinates')} :
                    </Typography>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="primary.main"
                    >
                      {selectedBinDetails?.latitude
                        ? selectedBinDetails?.latitude.substring(0, 8)
                        : '#'}
                      {''} /
                      <br />
                      {selectedBinDetails?.longitude
                        ? selectedBinDetails?.longitude.substring(0, 8)
                        : '#'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="#000"
                    >
                      {t('bins.fields.namePDC')} :
                    </Typography>
                    <Typography
                      fontFamily="Inter"
                      fontSize={14}
                      fontWeight={500}
                      color="primary.main"
                    >
                      {selectedBinDetails?.nom_pdc
                        ? selectedBinDetails?.nom_pdc
                        : '#'}
                    </Typography>
                  </Stack>
                </Stack>

                {/* Button */}
                <Box>
                  {selectedBinDetails && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={() => {
                        show('bins', `${selectedBinDetails?.id}`);
                      }}
                    >
                      More info
                    </Button>
                  )}
                </Box>
              </Stack>
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
