import {
  HttpError,
  IResourceComponentsProps,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import InputMask from "react-input-mask";
import {
  Autocomplete,
  Box,
  Button,
  Edit,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { Header } from "components/common/Header";
import { IIntegartor } from "interfaces/common";
import { States } from "constants/index";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useParams } from "@pankod/refine-react-router-v6";

export const IntegratorEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const {
    refineCore: { formLoading },
    saveButtonProps,
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IIntegartor, HttpError, IIntegartor>();
  const { id } = useParams();
  const { mutate } = useUpdate();
  const [showPassword, setshowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setshowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [record, setRecord] = useState<IIntegartor | null>(null);
  React.useEffect(() => {
    const fetchRecord = async () => {
      try {
        const response = await fetch(`/api/integrateurs/${id}`);
        const data = await response.json();
        setRecord(data);
        setValue("prenom", data?.integrateur?.prenom);
        setValue("nom", data?.integrateur?.nom);
        setValue("numeroTel", data?.integrateur?.numeroTel);
        setValue("ville", data?.integrateur?.ville);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRecord();
  }, [id, setValue]);

  //
  const [isTextFieldEnabled, setTextFieldEnabled] = useState(false);
  const handleClickEnableTextField = () => {
    if (isTextFieldEnabled) {
      handleEditPassword();
    }
    setTextFieldEnabled(!isTextFieldEnabled);
  };
  const handleEditPassword = () => {
    const userId = record?.id;
    mutate({
      resource: "users",
      id: userId ?? "",
      values: {
        password: watch("password"),
      },
    });
  };
  //
  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={<Header title={t("user.integrator.titles.edit")} />}
      wrapperProps={{
        sx: {
          border: 2,
          borderColor: "#E9E9E9",
          borderRadius: 2,
        },
      }}
    >
      <>
        <Box
          component="div"
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            bgcolor: "#fff",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Stack
            sx={{ width: "100%", justifyContent: "space-between", gap: 3 }}
          >
            {/* <input type="hidden" {...register('userId')} /> */}
            <Stack
              direction="row"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              {/* First Name */}

              <FormControl fullWidth>
                <FormLabel
                  required
                  sx={{
                    marginBottom: "8px",
                    fontFamily: "Inter",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "text.primary",
                  }}
                >
                  {t("user.fields.firstName")}
                </FormLabel>
                <TextField
                  {...register("prenom", {
                    required: t("errors.required.field", {
                      field: "First Name",
                    }),
                  })}
                  size="medium"
                  margin="none"
                  variant="outlined"
                  error={!!errors.prenom?.message}
                  InputProps={{
                    inputProps: {
                      style: { textTransform: "capitalize" },
                      maxLength: 50, // maximum length of characters allowed
                      onChange: (event) => {
                        const target = event.target as HTMLInputElement;
                        target.value =
                          target.value.charAt(0).toUpperCase() +
                          target.value.slice(1);
                      },
                    },
                  }}
                />
                {errors.prenom && (
                  <FormHelperText error>{errors.prenom.message}</FormHelperText>
                )}
              </FormControl>
              {/* Last Name */}

              <FormControl fullWidth>
                <FormLabel
                  required
                  sx={{
                    marginBottom: "8px",
                    fontFamily: "Inter",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "text.primary",
                  }}
                >
                  {t("user.fields.lastName")}
                </FormLabel>
                <TextField
                  {...register("nom", {
                    required: t("errors.required.field", {
                      field: "Last Name",
                    }),
                  })}
                  size="medium"
                  margin="none"
                  variant="outlined"
                  error={!!errors.nom?.message}
                  InputProps={{
                    inputProps: {
                      style: { textTransform: "capitalize" },
                      maxLength: 50, // maximum length of characters allowed
                      onChange: (event) => {
                        const target = event.target as HTMLInputElement;
                        target.value =
                          target.value.charAt(0).toUpperCase() +
                          target.value.slice(1);
                      },
                    },
                  }}
                />
                {errors.nom && (
                  <FormHelperText error>{errors.nom.message}</FormHelperText>
                )}
              </FormControl>
            </Stack>

            {/* Email */}
            <FormControl>
              <FormLabel
                required
                sx={{
                  marginBottom: "8px",
                  fontFamily: "Inter",
                  fontWeight: "700",
                  fontSize: "14px",
                  color: "text.primary",
                }}
              >
                {t("user.fields.email")}
              </FormLabel>
              <TextField
                {...register("email", {
                  required: t("errors.required.field", { field: "Email" }),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("errors.required.invalidMail"),
                  },
                })}
                size="medium"
                margin="none"
                variant="outlined"
                error={!!errors.email?.message}
              />
              {errors.email && (
                <FormHelperText error>{errors.email.message}</FormHelperText>
              )}
            </FormControl>

            {/* Phone Number */}
            <FormControl>
              <FormLabel
                required
                sx={{
                  marginBottom: "8px",
                  fontFamily: "Inter",
                  fontWeight: "700",
                  fontSize: "14px",
                  color: "text.primary",
                }}
              >
                {t("user.fields.gsm")}
              </FormLabel>
              <InputMask
                mask="99 99 99 99 99"
                disabled={false}
                {...register("numeroTel", {
                  required: t("errors.required.field", { field: "numeroTel" }),
                })}
              >
                {/* @ts-expect-error False alarm */}
                {(props: TextFieldProps) => (
                  <TextField
                    {...props}
                    size="medium"
                    margin="none"
                    variant="outlined"
                    error={!!errors.numeroTel?.message}
                  />
                )}
              </InputMask>
              {errors.numeroTel && (
                <FormHelperText error>
                  {errors.numeroTel.message}
                </FormHelperText>
              )}
            </FormControl>
            {/* Ville */}
            <FormControl fullWidth>
              <FormLabel
                required
                sx={{
                  marginBottom: "8px",
                  fontFamily: "Inter",
                  fontWeight: "700",
                  fontSize: "14px",
                  color: "text.primary",
                }}
              >
                {t("sector.fields.city")}
              </FormLabel>
              <Controller
                control={control}
                name="ville"
                rules={{
                  required: "City required",
                }}
                // defaultValue={null as any}
                render={({ field }) => (
                  <Autocomplete
                    size="medium"
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    options={States.map((state) => state.state_name)}
                    // }
                    renderInput={(params) => (
                      <TextField
                        {...register("ville", {
                          required: t("errors.required.field", {
                            field: "City",
                          }),
                        })}
                        {...params}
                        variant="outlined"
                        error={!!errors.ville?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              {errors.ville && (
                <FormHelperText error>{errors.ville.message}</FormHelperText>
              )}
            </FormControl>

            <Stack
              direction="row"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Password */}
              <FormControl variant="outlined" fullWidth>
                <FormLabel
                  disabled={!isTextFieldEnabled}
                  sx={{
                    marginBottom: "8px",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "text.primary",
                  }}
                >
                  {t("user.fields.password")}
                </FormLabel>
                <OutlinedInput
                  disabled={!isTextFieldEnabled}
                  id="outlined"
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  error={!!errors.password?.message}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{ backgroundColor: "white" }}
                  fullWidth
                />
                {errors.password && (
                  <FormHelperText error>
                    {errors.password.message}
                  </FormHelperText>
                )}
              </FormControl>
              <Button
                variant="outlined"
                onClick={handleClickEnableTextField}
                sx={{
                  marginTop: "24px",
                }}
              >
                {isTextFieldEnabled
                  ? t("user.fields.save")
                  : t("user.fields.edit")}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    </Edit>
  );
};
