import React, { useState } from "react";
import {
  IResourceComponentsProps,
  HttpError,
  useTranslate,
  useNavigation,
  useApiUrl,
} from "@pankod/refine-core";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  GridActionsCellItem,
  List,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@pankod/refine-mui";

import axios, { AxiosResponse } from "axios";
import { BACKENDINFO, IIntegartor } from "interfaces/common";
import { Header } from "components/common/Header";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
};

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case "SORT":
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      };

    case "FILTER":
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      };
    case "REFRESH":
      return {
        ...state,
        refresh: !state.refresh,
      };
    case "PAGE":
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      };
    case "PAGESIZE":
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      };
    case "ROWS":
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      };

    default:
      return state;
  }
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export const IntegratorsList: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const { edit } = useNavigation();
  const t = useTranslate();

  const [state, dispatch] = React.useReducer(reducer, initState);
  const { dataGridProps } = useDataGrid<IIntegartor, HttpError>({
    initialPageSize: 10,
  });

  const columns = React.useMemo<GridColumns<IIntegartor>>(
    () => [
      {
        field: "nom",
        headerName: t("user.fields.firstName"),
        valueGetter: ({ row }) => row?.nom,
        headerAlign: "center",
        align: "center",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "prenom",
        headerName: t("user.fields.lastName"),
        valueGetter: ({ row }) => row?.prenom,
        headerAlign: "center",
        align: "center",
        flex: 1,
        minWidth: 100,
      },

      {
        field: "numeroTel",
        headerName: t("user.fields.gsm"),
        valueGetter: ({ row }) => row?.numeroTel,
        flex: 1,
        minWidth: 150,
        sortable: false,
      },

      {
        field: "email",
        headerName: t("user.fields.email"),
        valueGetter: ({ row }) => row?.user?.email,
        flex: 1,
        minWidth: 150,
        sortable: false,
      },
      {
        field: "city",
        headerName: t("user.fields.city"),
        valueGetter: ({ row }) => row?.ville,
        flex: 1,
        minWidth: 150,
        sortable: false,
      },

      {
        field: "actions",
        type: "actions",
        headerName: t("table.actions"),
        flex: 1,
        minWidth: 100,
        sortable: false,
        getActions: function render(rows) {
          return [
            <GridActionsCellItem
              key={1}
              label={t("buttons.edit")}
              icon={<Edit color="success" />}
              onClick={() =>
                rows?.row?.userId && edit("integrateurs", rows?.row?.userId)
              }
            />,

            <GridActionsCellItem
              key={2}
              label={t("buttons.delete")}
              icon={<DeleteIcon color="error" />}
              onClick={() => {
                rows?.row?.user?.id &&
                  deleteOne(
                    rows.row.user.id,
                    rows?.row?.nom ?? "",
                    rows?.row?.prenom ?? ""
                  );
              }}
            />,
          ];
        },
      },
    ],
    [t]
  );
  const deleteOne = (id: Number, nom: string, prenom: string) => {
    Swal.fire({
      titleText: t("buttons.delete") + ` ${nom} ${prenom} ?`,
      text:
        t("notifications.confirmDeleteP1") +
        `${nom} ${prenom} ? ` +
        t("notifications.confirmDeleteP2"),
      showCancelButton: true,
      cancelButtonText: t("buttons.cancel"),
      confirmButtonText: t("buttons.delete"),
      reverseButtons: true,
      customClass: {
        title: "sw-delete-title",
        cancelButton: "sw-delete-cancel",
        confirmButton: "sw-delete-confirm",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/api/integrateurs/${id}`, { withCredentials: true })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: `${nom} ${prenom}` + t("notifications.deleteSuccess"),
                showConfirmButton: false,
                timer: 1500,
              });

              dispatch({ type: "REFRESH" });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${nom} ${prenom}` + t("notifications.deleteError"),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${nom} ${prenom}` + t("notifications.deleteError"),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  const getData = () => {
    axios
      .get(
        `${apiUrl}/integrateurs/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`,
        { withCredentials: true }
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          dispatch({ type: "ROWS", payload: res.data });
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  React.useEffect(() => {
    getData();
  }, [state.refresh]);

  return (
    <List
      title={
        <Header
          title="Integrator"
          description="Visualize, alter, or administer your Integrator base."
        />
      }
      wrapperProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        filterModel={undefined}
        autoHeight
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        sx={{
          ...dataGridProps.sx,
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={(data) => {
          dispatch({ type: "FILTER", payload: data.items });
        }}
        onSortModelChange={(data) =>
          dispatch({
            type: "SORT",
            payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
          })
        }
        pagination
        paginationMode="server"
        onPageChange={(data) => dispatch({ type: "PAGE", payload: data })}
        onPageSizeChange={(data) =>
          dispatch({ type: "PAGESIZE", payload: data })
        }
        rows={state.rows}
        rowCount={state.total}
        page={state.page}
        pageSize={state.pageSize}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </List>
  );
};
