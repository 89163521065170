import React, { useState } from 'react';
import QRCode from 'qrcode';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
  useApiUrl,
} from '@pankod/refine-core';
import { BACKENDINFO, IBin, IRequest } from 'interfaces/common';
import {
  Box,
  Button,
  DataGrid,
  DateField,
  Grid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  LinearProgress,
  LinearProgressProps,
  Show,
  Stack,
  Typography,
} from '@pankod/refine-mui';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import QrCodeIcon from '@mui/icons-material/QrCode';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import axios, { AxiosResponse } from 'axios';
import { BinStatus } from 'components/bin/list/BinStatus';
import Swal from 'sweetalert2';
import { useParams } from '@pankod/refine-react-router-v6';

const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
};
const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      };

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      };
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      };
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      };
    case 'ROWS':
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      };

    default:
      return state;
  }
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
interface LinearProgressWithLabelProps extends LinearProgressProps {
  value: number;
}
function getColor(value: number): 'primary' | 'warning' | 'secondary' {
  if (value <= 50) {
    return 'primary';
  } else if (value <= 70) {
    return 'warning';
  } else {
    return 'secondary';
  }
}
function LinearProgressWithLabel(props: LinearProgressWithLabelProps) {
  const { value } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          // value={value}
          sx={{ color: getColor(value), height: '6px' }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export const BinShow: React.FC<IResourceComponentsProps> = () => {
  //
  const [imageUrl, setImageUrl] = useState('');
  const apiUrl = useApiUrl();
  const t = useTranslate();

  const [refresh, setrefresh] = useState(false);

  const { id } = useParams();
  const {
    queryResult: { data, isLoading },
  } = useShow<IBin>();
  const binDetails = data?.data;

  const level =
    (binDetails?.citizens_requests
      ?.map((fill) => fill.percentage)
      ?.pop() as number) || 0;

  // function generateCode(srb: BinDetails): string {
  //   const createdAt = new Date(srb.createdAt);
  //   const day = createdAt.getDate().toString().padStart(2, '0');
  //   const month = (createdAt.getMonth() + 1).toString().padStart(2, '0');
  //   // const year = createdAt.getFullYear().toString().substr(-2);
  //   const id = srb.id.toString().padStart(4, '0');
  //   const zone = srb.zone.toUpperCase();
  //   return `${day}${month}${zone}${id}`;
  // }
  // const codeSrb: BinDetails = {
  //   id: binDetails?.id !== undefined ? binDetails.id : 0,
  //   createdAt: binDetails?.createdAt !== undefined ? binDetails.createdAt : '',
  //   zone: binDetails?.zone !== undefined ? binDetails.zone : '',
  // };

  // const code = generateCode(codeSrb);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
  });

  const columns = React.useMemo<GridColumns<IRequest>>(
    () => [
      {
        field: 'iamge',
        headerName: 'Image',
        minWidth: 100,
        renderCell: function render({ row }) {
          return (
            <>
              <img
                src={row?.image}
                alt=""
                style={{
                  height: '50px',
                  maxWidth: '100px',
                }}
              />
            </>
          );
        },
      },

      {
        field: 'createdAt',
        headerName: t('request.fields.createdAt'),
        flex: 1,
        minWidth: 150,
        renderCell: function render({ row }) {
          return (
            <DateField
              value={row.createdAt}
              format="LLL"
              sx={{ fontSize: '14px', fontFamily: 'Inter' }}
            />
          );
        },
      },

      {
        field: 'percentage',
        headerName: t('request.fields.percentage'),
        valueGetter: ({ row }) => row?.percentage,
        flex: 1,
        minWidth: 150,
        sortable: false,
      },

      {
        field: 'actions',
        type: 'actions',
        headerName: t('table.actions'),
        flex: 1,
        minWidth: 100,
        sortable: false,
        getActions: (rows) => [
          <GridActionsCellItem
            key={2}
            label={t('buttons.delete')}
            icon={<DeleteIcon color="error" />}
            onClick={() => {
              rows.row.id && deleteOne(rows.row.id);
            }}
          />,
        ],
      },
    ],
    [t]
  );

  const deleteOne = (id: Number) => {
    Swal.fire({
      titleText: t('buttons.delete') + ` Request ?`,
      showCancelButton: true,
      cancelButtonText: `Annuler`,
      confirmButtonText: 'Supprimer',
      reverseButtons: true,
      customClass: {
        title: 'sw-delete-title',
        cancelButton: 'sw-delete-cancel',
        confirmButton: 'sw-delete-confirm',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/api/requests/${id}`, { withCredentials: true })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: `Request` + t('notifications.deleteSuccess'),
                showConfirmButton: false,
                timer: 1500,
              });
              setrefresh(true);
            } else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Request` + t('notifications.deleteError'),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: `Request` + t('notifications.deleteError'),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  const downloadQrCode = () => {
    const filename = `qrcode_${binDetails?.srb}.png`;
    const link = document.createElement('a');
    link.download = filename;
    link.href = imageUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const qrcode = `${binDetails?.srb}`;
  const generateQrCode = async () => {
    try {
      const response = await QRCode.toDataURL(qrcode);
      setImageUrl(response);
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedBinDetails, setSelectedBinDetails] = useState<
    IRequest | undefined
  >();
  const handleClick = (id?: GridRowId) => {
    const requests = binDetails?.citizens_requests;
    const request = requests?.find((r) => r.id === id);
    setSelectedBinDetails(request);
  };
  const [state, dispatch] = React.useReducer(reducer, initState);
  const getData = () => {
    axios
      .get(
        `${apiUrl}/requests/bin/${id}/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`,
        { withCredentials: true }
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          dispatch({ type: 'ROWS', payload: res.data });
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  React.useEffect(() => {
    getData();
  }, [refresh]);

  return (
    <Grid container minHeight="85vh" p={0} m={0}>
      <Grid item xs={12} md={12} lg={9} p={1}>
        <Show
          isLoading={isLoading}
          title={
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography
                fontFamily="Inter"
                fontSize={20}
                fontWeight={500}
                color="#000"
              >
                SRB #{binDetails?.srb}
              </Typography>
              <BinStatus status={binDetails?.etat} />
            </Stack>
          }
          wrapperProps={{
            sx: {
              border: 2,
              borderColor: '#E9E9E9',
              borderRadius: 2,
            },
          }}
        >
          <Stack>
            <Box sx={{ width: '100%', height: '100%' }}>
              <LinearProgressWithLabel value={level} color={getColor(level)} />
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={2}
              sx={{ mt: 3 }}
            >
              {/* BinInfo */}
              <Stack justifyContent="space-between" spacing={1} width="100%">
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="#000"
                  >
                    {t('bins.fields.typePDC')} :
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {binDetails?.type_pdc?.type}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="#000"
                  >
                    {t('bins.fields.createdAt')} :
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {binDetails?.createdAt
                      ? binDetails?.createdAt.substring(0, 10)
                      : '#'}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography fontSize={14} fontWeight={500} color="#000">
                    {t('bins.fields.deployedAt')} :
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {binDetails?.date_deploiement
                      ? binDetails?.date_deploiement.substring(0, 10)
                      : '#'}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="#000"
                  >
                    {t('zone.fields.city')} :
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {binDetails?.secteur?.ville?.ville}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="#000"
                  >
                    {t('bins.fields.sector')} :
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {binDetails?.secteur?.secteur}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="#000"
                  >
                    {t('bins.fields.zone')} :
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {binDetails?.secteur?.ville?.zone}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography fontSize={14} fontWeight={500} color="#000">
                    {t('bins.fields.coordinates')} :
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {binDetails?.latitude
                      ? binDetails?.latitude.substring(0, 8)
                      : '#'}{' '}
                    /{' '}
                    {binDetails?.longitude
                      ? binDetails?.longitude.substring(0, 8)
                      : '#'}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="#000"
                  >
                    {t('bins.fields.namePDC')} :
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize={14}
                    fontWeight={500}
                    color="primary.main"
                  >
                    {binDetails?.nom_pdc}
                  </Typography>
                </Stack>
              </Stack>
              {/* Map */}
              <Box
                component="div"
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  border: 2,
                  borderColor: '#E9E9E9',
                  mt: 2,
                }}
              >
                {isLoaded && (
                  <GoogleMap
                    center={{
                      lat: Number(binDetails?.latitude),
                      lng: Number(binDetails?.longitude),
                    }}
                    zoom={14}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    options={{
                      streetViewControl: false,
                    }}
                  >
                    <Marker
                      position={{
                        lat: Number(binDetails?.latitude),
                        lng: Number(binDetails?.longitude),
                      }}
                    />
                  </GoogleMap>
                )}
              </Box>
            </Stack>
            {/* Request Grid */}
            <Box sx={{ width: '100%', mt: 4 }}>
              <DataGrid
                // {...dataGridProps}
                columns={columns}
                filterModel={undefined}
                autoHeight
                rowHeight={80}
                rowsPerPageOptions={[10, 20, 50, 100]}
                onRowClick={({ id }) => handleClick(id)}
                sx={{
                  // ...dataGridProps.sx,
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                  },
                }}
                sortingMode="server"
                filterMode="server"
                onFilterModelChange={(data) => {
                  dispatch({ type: 'FILTER', payload: data.items });
                }}
                onSortModelChange={(data) =>
                  dispatch({
                    type: 'SORT',
                    payload: [
                      [data[0].field, data[0].sort?.toLocaleUpperCase()],
                    ],
                  })
                }
                pagination
                paginationMode="server"
                onPageChange={(data) =>
                  dispatch({ type: 'PAGE', payload: data })
                }
                onPageSizeChange={(data) =>
                  dispatch({ type: 'PAGESIZE', payload: data })
                }
                rows={state.rows}
                rowCount={state.total}
                page={state.page}
                pageSize={state.pageSize}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />

              {/* {binDetails?.citizens_requests?.map((requet) => (
                <Typography> {requet.percentage} abla</Typography>
              ))} */}
            </Box>
          </Stack>
        </Show>
      </Grid>
      <Grid xs={12} md={12} lg={3} p={1}>
        <Stack spacing={2} p={2}>
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              width: '100%',
              border: 2,
              borderColor: '#E9E9E9',
              borderRadius: 2,
              overflow: 'hidden',
              bgcolor: '#fff',
              p: 1,
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={1}
              pb={1}
            >
              <div>
                <img src={imageUrl} alt="" style={{ width: '160px' }} />
              </div>
              <Button
                variant="contained"
                startIcon={<QrCodeIcon />}
                onClick={generateQrCode}
                sx={{ width: '100%' }}
              >
                Generate Qr Code
              </Button>

              <Button
                onClick={() => downloadQrCode()}
                variant="contained"
                startIcon={<SaveAltIcon />}
                sx={{ width: '100%' }}
              >
                Download
              </Button>
            </Stack>
          </Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              width: '100%',
              height: '100%',
              border: 2,
              borderColor: '#E9E9E9',
              borderRadius: 2,
              overflow: 'hidden',
              bgcolor: '#fff',
              p: 1,
            }}
          >
            <Stack
              sx={{ width: '400px', height: '400px' }}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {selectedBinDetails && (
                <Box
                  component="div"
                  sx={{
                    width: '350px',
                    height: '350px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                  }}
                >
                  <img
                    src={selectedBinDetails.image}
                    alt="BinImage"
                    style={{
                      width: '350px',
                      height: '350px',
                      objectFit: 'cover',
                      borderRadius: '12px',
                    }}
                  />
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
